@import '../functions/list';

/* LISTCONT  */

.RoomsListCont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-items: center;
    justify-content: center;

    
}

.RoomCardsContCont {
    margin: 0 1rem;
    position: relative;
}

/* CARDS */
.RoomCardsCont {
    width: 20rem;
    height: 30rem;
    background-color: nth($colors, 1);
    border-radius: 10px;
    margin: 1rem auto;
    color: white;
    transition: 0.5s all ease-in-out;
    padding-bottom:  0.5rem;
    cursor: pointer;
    opacity: 80%;
}

.ExtraPaddingBottom {
    height: fit-content;
    padding-bottom:  2rem;
    opacity: 100%;
}

.RoomCardsCont:hover {
    opacity: 100%;
    background-color: rgb(58, 102, 102);

}

.RoomCardImg {
    object-fit: cover;
    width: 100%;
    border-radius: 10px;
    height: 20rem; 
    transition: all 0.5s ease-in-out;
}


.ImgClosed {
    height: 30rem;    
    transition: all 0.5s ease-in-out;

}



.RoomCardsTittle {
    font-size: 2rem;
    font-weight: bolder;
    letter-spacing: 2px;
    font-family: 'Barlow Condensed', sans-serif;
    margin: 0.5rem 0rem;
    padding: 0rem 2rem;
    transition: all 0.5s ease-in-out;
    position: relative;
    bottom: 6rem;
    filter: drop-shadow(rgb(58, 102, 102) 2px 2px);
}


.DownMove {
 font-size: 1.5rem;
 transform: translateY(60px);
}

.RoomCardsTexts {
    display: flex;
    flex-direction: column;
    height: 10rem;
    position: relative;
    bottom: 2rem;
}


.RoomCardsDescription {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    padding: 0rem 2rem;
    margin: 0.2rem;
    transition: 0.5s all ease-in-out;

}

.RoomCardsDescriptionText {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    padding: 0rem 2rem;
    margin: 0.2rem;
    transition: 0.5s all ease-in-out;

 

}



.RoomCardSubTittle {
    font-size: 1rem;
    margin: 0 0.1rem;  
 
    font-weight: bolder;
    letter-spacing: 2px;
    font-family: 'Barlow Condensed', sans-serif;

}

.green {
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: #576F72;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;

}

.RoomCardAlert {
    color: black;
    background-color: aliceblue;
    width: fit-content;
    margin: 0.5rem auto;
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
    font-weight: bolder;
    border-radius: 2px;
    transition: all 0.5s ease-in-out;
}



.displayNone {
    transition: all 0.5s ease-in-out;
    display: none;
}

.linkRoom {
    text-decoration: none;
}



/* ROOMCARD DETAIL */

.RoomCardsDetailCont {
    width: 50%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    margin: 1rem auto;
    color: black;
    transition: 0.5s all ease-in-out;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;
}

.DetailImgCont {
    width: 100%;
    
}

.DetailDescriptionCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

}

.RoomCardsDetailTittle {
    font-size: 3rem;
    font-weight: bolder;
    letter-spacing: 2px;
    font-family: 'Barlow Condensed', sans-serif;
    margin: 0.5rem 0rem;
    padding: 0rem 2rem;
    transition: all 0.5s ease-in-out;
    color: nth($colors, 1);
}


.priceDetailCont {
    width: 120%;
}


.amenitiescont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 1rem;
    margin-bottom: 2rem;
}

.ametiniesItemCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0.5rem;
    width: 7rem;
}
.amenitiesimg {
    width: 1rem;
    height: 1rem;
    margin: 0.2rem;
}

.amenitiestext {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 0.8rem;
    margin: 0.2rem;
    width: 5rem;
}

.RoomCardDetailPriceTittle {
    font-size: 2rem;
    font-weight: bolder;
    letter-spacing: 2px;
    font-family: 'Barlow Condensed', sans-serif;
    margin: 0.5rem 0rem;
    padding: 0.5rem 1rem;
    transition: all 0.5s ease-in-out;
    color: white;
    width: fit-content;
    margin: 0.5rem auto;
    border-radius: 5px;
    background-color: nth($colors, 1);

}


.pricingCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.RoomCardsDetailDescription {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    padding: 0rem;
    margin: 0.5rem;
    transition: 0.5s all ease-in-out;

}

.dateTopText {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
    margin: 0.2rem;
    transition: 0.5s all ease-in-out;
    color: nth($colors, 2);
}

.chooseTimeButtonCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.chooseTimeButton {
    font-family: 'Montserrat', sans-serif;
    color: white;
    padding: 1rem;
    margin: 0.25rem;
    border-radius: 10px;
    border: none;
    background-color: nth($colors, 6);
    transition: 0.5s all ease-in-out;
    cursor: pointer;
}

.chooseTimeButton2 {
    font-family: 'Montserrat', sans-serif;
    color: white;
    padding: 1rem;
    margin: 0.25rem;
    border-radius: 10px;
    border: none;
    border: 1px solid black;
    color: black;
    background-color: white;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
}


.chooseTimeButton:focus{
    background-color: nth($colors, 1);
}

.chooseTimeButton:hover{
    background-color: nth($colors, 1);
}


.reselectButton {
    font-family: 'Montserrat', sans-serif;
    color: white;
    padding: 1rem;
    margin: 0.25rem;
    border-radius: 10px;
    border: none;
    background: nth($colors, 1);
    background: linear-gradient(304deg,  nth($colors, 1) 0%,  nth($colors, 2) 100%);
  font-weight: bold;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
    opacity: 50%;
}

.reselectButton:focus{
    opacity: 100%;
}

.reselectButton:hover{
    opacity: 100%;
}

.imposibleButton {
    color: white;
    background: red;
  background: linear-gradient(304deg, rgb(237, 125, 125) 0%,  rgb(198, 98, 98) 100%);
    opacity: 100%;
}

.chooseHaircutButtonContCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chooseHaircutButton {
    font-family: 'Montserrat', sans-serif;
    color: white;
    padding: 1rem;
    margin: 0.25rem;
    border-radius: 10px;
    border: none;
    background-color: nth($colors, 6);
    transition: 0.2s all ease-in-out;
    cursor: pointer;
    width: 100%;
    position: relative;
}

.chooseHaircutText {
    font-family: 'Montserrat', sans-serif;
    color: nth($colors, 6);
}


.deleteHaircutButton {
    font-family: 'Montserrat', sans-serif;
    color: white;
    padding: .25rem;
    margin: 0;
    border-radius: 7px;
    border: none;
    background-color: nth($colors, 7);
    transition: 0.2s all ease-in-out;
    cursor: pointer;
    width: fit-content;
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    aspect-ratio: 1/1;
    opacity: 1;
}

.deleteHaircutButton:hover {
    background-color: rgb(155, 58, 58);
    opacity: 1;
}

.chooseHaircutButton:hover{
    color: white;
    background-color: nth($colors, 1);
}
.chooseHaircutButtonColor {
    background-color: nth($colors, 5);
    color: rgb(0, 72, 0);
}
.chooseHaircutButtonColor:hover {
    background-color: nth($colors, 5);
    color: rgb(0, 72, 0);
}

.yellowBG {
    background-color: nth($colors, 1);
}

.yellowBG:hover {
    background-color: nth($colors, 3);
}

.w10 {
    width: 20%;
}

.w30 {
    width: 30%;
}

.w50 {
    width: 50%;
}



.wt {
    padding: 0rem 0.5rem;
}

.margintop{
    margin-top: 1rem;
}

.white {
    color: white;
}

.lighter {
    font-weight: lighter;
}
.ContactInfoCont {
    width: 90%;
    padding: 2rem 2rem;
    color: nth($colors, 1);
    background-color: nth($colors, 2);
    border-radius: 10px;
}


/* INPUT CHECKOUT */

.inputCheckOutCont {
    display: flex;
    flex-direction: column;
}

.inputCheckOut {
    height: 2rem;
    width: 50%;
    margin: 0.1rem 0rem;
    padding-left: 1rem;
    font-size: 1rem;
}

.inputCheckOut::placeholder {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    justify-content: center;

}

.noShowForm {
    display: none;

}


.positionR {
    position: relative;
    left: 10rem;
}

.CheckOutCont {
    width: 100%;
    padding: 2rem 2rem;
    color: #576F72;

}

.checkoutPaymentCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.checkoutPaymentContOptions {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.checkoutPayOpCont {
    margin: 0rem 0rem;
}

.checkOutImgCont {
    display: flex;
    flex-direction: row;   
}

.checkOutImg {
    width: 5rem;
    margin: 0 auto;
    margin: 2rem;   

}

.checkOutImgSmall {
    width: 3rem;
    margin: 0 auto;
    margin: 2rem;   
}

.ButtonNone {
    border: none;
    background: none;
    cursor: pointer;
    transition: 0.5s all ease-in-out;
}

.ButtonNone:hover {
    border: none;
    background: none;
    transform:translateY(2px)
}


.submitButton {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 0.2rem 0rem;
    color: white;
    border: none;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    background-color: nth($colors, 6);
    font-family: 'Barlow Condensed', sans-serif;
    border-radius: 5px;
    transition: 0.3s all ease-in-out;
}

.submitButton:hover {
    background-color: nth($colors, 1);
}

.submitButtonBooked {
    height: 2rem;
    width: 2rem;
    line-height:0.5rem;
    margin: 0.2rem 0rem;
    color: white;
    border: none;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    background-color: nth($colors, 1);
    font-family: 'Barlow Condensed', sans-serif;
    border-radius: 50%;
    transition: 0.3s all ease-in-out;
}

.checkSubmitTick {
    width: 1.2rem;
    height: 1.2rem;
    filter: invert(1);
}

.checkedoutBook {
    font-size: 0.8rem;
    border-radius: 5px;
    margin: 1rem auto 0.5rem;
    color: #7D9D9C;
    transition: 0.3s all ease-in-out;
    width: fit-content;

}

.checkOutImgFlag {
    width: 1.5rem;
    height: 1.5rem;
}

.padding {
    padding: 0rem 3rem;
}


.greenhighlight {
    border-radius: 0 !important;
    color: white !important;
    background-color: #7D9D9C !important;
}

.bigtext {
    font-size: 1rem;
}


.disclosureDaysCont {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #576F72;
    color: white;
    padding: 0.5rem 1rem;
    width: fit-content;
    height: fit-content;
    margin: 1rem auto;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.disclosureDaysCont:hover {
    padding: 0.5rem  1.5rem;
}

.closeButtonDiscl {
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    opacity: 50%;
    background-color:  rgb(228, 141, 141);
    color: rgb(119, 27, 27) ;
    border: none;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    z-index: 1000;
}

.closeButtonDiscl:hover {
    opacity: 100%;
}

.disclosureDaysContPrices {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #576F72;
    color: white;
    padding: 0.5rem 1rem;
    width: fit-content;
    height: fit-content;
    margin: 1rem auto;
    border-radius: 5px;
    flex-wrap: wrap;
}

.whiteback {
    background-color: whitesmoke;
    color: #576F72;
    padding: 0.25rem;
    border-radius: 5px;
}
.backgreenclear {
    background-color: #7D9D9C;
    padding: 0.25rem;
    border-radius: 5px;
}

.w5rem {
    width: 4rem;
}

.zindex1000000 {
    z-index: 30000000000 !important;
}

/* MEDIA QUERY */

@media only screen and (min-width: 601px) {

    .none-desktop {
        display: none !important;
    }
 }

 @media only screen and (min-width: 600px) and  (max-width: 1200px) {


 }


 @media only screen and (min-width: 100px) and  (max-width: 600px) {

    .none-mobile {
        display: none !important;
    }

    .RoomCardsDetailCont {
        width: 80%;
        display: flex;
        flex-direction: column;
        padding: 1rem 1rem;
        margin: 1rem auto;
    }

    .DetailDescriptionCont {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
    
    }

    .priceDetailCont {
        width: 100%;
    }

    .RoomCardsDetailTittle {
        font-size: 2rem;

    }

    .amenitiescont {
        width: 100%;
        padding: 1rem;
        margin-top: 1rem;    
    }
    
    .ContactInfoCont {
        width: 85%;
        padding: 2rem 2rem;
        color: white;
        border-radius: 10px;
    }

    .positionR {
        position: block;
        left: 0;
        margin-top: 0.5rem;
    }

    .formAgregarProducto {
        margin: 0;
        padding: 0;
    }
    


    .inputCheckOut {
        height: 2rem;
        width: 80%;
    }
    
    .submitButton {
        margin: 2rem 0rem 0.5rem 0rem;    
    }

    
    .checkoutPaymentContOptions {
        flex-direction: row;
    }
    
    .padding {
        padding: 0.5rem;
    }

    .checkOutImgSmall {
        width: 5rem;
        height: 5rem;

    }

    .deleteHaircutButton {

        padding: .25rem .15rem;

    }
    

}