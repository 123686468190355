@import '../functions/list';

.allSectionCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 100vh;
    width: 100vw;
}


.section2 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 100vh;
    width: 100vw;
}

.spacearound {
    justify-content: space-around;
}

.pinkgradient {
    color: white;
    background: nth($colors, 2);
    background: linear-gradient(304deg, nth($colors, 2) 0%, rgb(143, 158, 212) 100%);
    z-index: 1000;
    position: absolute;
    bottom: 4rem;
}

.micrositeCover {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    min-height: 100vh;
    height: fit-content;
    width: 100vw;
    color: nth($colors, 1);
    background: nth($colors, 2);
    background: linear-gradient(304deg, whitesmoke 0%, rgba(255,255,255,1) 100%);


}

.chooseTimeButtonCont {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.imgCover {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit:cover;
    top: 0;
    opacity: 10%;

}


.header {
    height: 100vh;
    width: 100vw;
    color: nth($colors, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.row {
    flex-direction: row;
}

.rowInv {
    flex-direction: row;
}


.column {
    flex-direction: column;
}

// @font-face {
//     font-family: "Fontspring-DEMO-bogue-regular";
//     src: url("../fonts/Bogue-Serif-Font-Family/Fontspring-DEMO-bogue-regular.otf");
//   }

// .micrositeTittle {
//     font-family: "Fontspring-DEMO-bogue-regular";
//     font-size: 5rem;
//     padding-top: 0rem;
//     z-index: 100;
//     font-weight: 400;
//     margin: 0rem 0rem 2rem;
// }

.inverseText {
    display: inline-block;
   transform: rotate(180deg);
position: relative;
top: 0.35rem;
}

.micrositeDescription {
    font-family: nth($font-family, 4); //montserrat
    font-weight: 300;
    padding: 0 20%;
    font-size: 1.2rem;
    z-index: 100;
    margin: 0 0 1rem 0;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15rem auto;
}


.logoMicrosite {
    position: absolute;
    width: 7rem;
    top: 1rem;
    left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    font-family: 'Wire One', sans-serif;
    margin: 0.5rem 0;
    z-index: 300;
    color: white;
    cursor: pointer;
    transition: 0.5s all ease-in-out;
}

.logoNoBg {
    width: 7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    font-family: 'Wire One', sans-serif;
    position: absolute;
    top: 3rem;
    z-index: 300;
    color: white;
    cursor: pointer;
    transition: 0.5s all ease-in-out;
    mix-blend-mode: multiply;
}


.logoCentered {
    width: 10rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    font-family: 'Wire One', sans-serif;
    margin: 1rem;
    z-index: 300;
    color: white;
    cursor: pointer;
    transition: 0.5s all ease-in-out;
}

.logoMicrosite:hover {
    transform: scale(1.02);
}

.logoMicrositeLoading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    font-family: 'Wire One', sans-serif;
    font-size: 3rem;
    margin: 2rem 0;
    width: max-content;
    z-index: 150;
    color: black !important;
}

.logobackMicrosite {
    color: nth($colors, 1);
    background-color: nth($colors, 5);
    padding: 0rem 0.5rem;
    border-radius: 5px;
    margin: 0rem 0.4rem;
}

.servicesButton {
    
    z-index: 100;
    border: none;
    padding: 1.5rem 2rem;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1.4rem;
    color: white;
    border-radius: 10px;
    filter: grayscale(0.5);
    background: nth($colors, 2);
background: linear-gradient(304deg, nth($colors, 2) 0%, nth($colors, 1) 100%);
    cursor: pointer;    
    transition: 0.5s all ease-in-out;
    margin: 0rem;
    width: 30%;
    box-sizing: border-box;
}

.servicesButton:hover {
    filter: grayscale(0);
}


.servicesButtonPeq {
    background: nth($colors, 1);
    background: linear-gradient(304deg, nth($colors, 2) 0%, nth($colors, 6) 100%);
    padding: .5rem 1rem;
    font-size: 1rem;
    border-radius: 7px;
}


.bluegradient2 {
    border: none;
    font-family: 'Barlow Condensed', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    margin: 0 auto;
    text-align: center;
    gap: .5rem;
    color: black;

    font-size: .9rem;
    box-sizing: border-box;
    background: nth($colors, 2);
    background: linear-gradient(304deg, nth($colors, 5) 0%, nth($colors, 2) 100%);
  }


  .whatsappLogoServicesButton {
    width: 1.2rem;
    aspect-ratio: 1/1;
    margin: 0rem auto;
    transition: 0.2s all ease-in-out;
    cursor: pointer;
    }
    
    .whatsappLogoServicesButton:hover {
     filter: drop-shadow(1px 1px 5px nth($colors, 2) ) ;
    }
    


.micrositeSocialMediaCont {
    display: flex;
    flex-direction: row;
    margin: 2rem 0rem;
    align-items: center;
    justify-items: center;

}

.micrositeSocialMediaCont {
    z-index: 100;

}
.socialMediaIcon {
    
    width: 2rem;
    height: 2rem;
    margin: 0.5rem;
    opacity: 80%;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.socialMediaIcon:hover {
    opacity: 100%;
}


.micrositeminitext {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0.5rem;
    font-family: nth($font-family, 6);
    font-size: 1rem;
    color: nth($colors, 1);
}

.miniImage {
    width: 1rem;
    height: 1rem;
}

.invert {
    filter: invert(0);
}

.alignCenter {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 100;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    background: none;
    border: none;
}


.alignCenterTopMap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 100;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    background: none;
    border: none;
}

.map {
    border-radius: 15px;
    border: none;
    right: 5rem;
    z-index: 150;
    width: 100%;
    height: 15rem;
    box-shadow: 10px 10px 10px rgb(0, 0, 0,  10%);
}

a {
    text-decoration: none;
    cursor: pointer;
}

.linkSite {
    z-index: 200;
}


/* ROOMS AND MESSAGES */

.roomsandmessCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
}

/* COMMENTS */

.ourGuests {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 2rem;
    font-weight: 100;
}

.commentsCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
}
.commentCont {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 60%;
    color: black;
    background-color: whitesmoke;
    padding: 1rem;
    border-radius: 10px;
    margin: 1rem;
}

.commentDash {
    width: 15rem;
}


.commentImg {
    width: 2rem;
    height: 2rem;
    margin: 0;
}

.commentName {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
}

.commentText {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    text-align: left;
}

.leaveMessage {
    width: 10rem;
    padding: 1rem;
    border: none;
    background-color: whitesmoke;
    border-radius: 5px;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    opacity: 60%;
    transition: 0.6s all ease-in-out;
    cursor: pointer;
}

.leaveMessage:hover {
    opacity: 100%;
    color: nth($colors,1);;
    background: #576F72;
}


footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0rem;
    color: white;
    position: relative;
    width: 100vw;
}

.footerWhatsappText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
 
}

.gap25 {
  gap:  .25rem
}

.footerAdressText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
    color: #ffffff;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    font-family: 'Barlow Condensed', sans-serif;
 

}

.footerSubColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    box-sizing: border-box;
    width: 60%;
    gap: 1rem;
}
.footerMap {
    border-radius: 0rem;
    height: 250px;
    width: 40%;
    border: none;
    margin: 0;

}



.footerTexts {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0.5rem;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    color: nth($colors,1);;
}

.googleMaps {
    width: 30%;
    aspect-ratio: 3/2;
    border: none;
    border-radius: 10px;
}
.myDataCont {
    width: 50%;
    display: flex;
    flex-direction: row;
}


.sep {
    margin: 0 3rem;
}
.wavyPeq {
    width: 2.5rem;
    margin: 0 1rem;
}
.alignLeft {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    border: none;
}

.footerTextItem {
    margin: 0.5rem 0;

    color: #ffffff;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    font-size: .8rem;
    font-family: nth($font-family, 4);
}

.footerTextItem:hover {
    color: nth($colors, 2);
}

.center {
    align-items: center;
}

.flexend {
    align-items: flex-end;
}


.logoMicrositeFooter {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    font-family: 'Wire One', sans-serif;
    font-size: 3rem;
    margin: 0.5rem 0;
    width: max-content;
    color: white;
}

.logobackMicrositeFooter {
    color: #576F72;
    background-color:  white;
    padding: 0rem 0.5rem;
    border-radius: 5px;
    margin: 0rem 0.4rem;
}

.footerSep {
    filter: invert(1);
}

.showCalendarButton {
    z-index: 100;
    border: none;
    padding: 0.5rem 1rem;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1.2rem;
    background: none;
    color: white;
    border-radius: 10px;
    filter: grayscale(0.5);
    background: nth($colors, 2);
    background: linear-gradient(304deg, nth($colors, 2) 0%, nth($colors, 1) 100%);
    cursor: pointer;    
    transition: 0.5s all ease-in-out;
}

.showCalendarButton:hover {
    filter: grayscale(0);
}

.button2Show {
    z-index: 100;
    padding: 0.5rem 0.75rem;
    color: nth($colors, 1);
    background: nth($colors, 5);
  background: linear-gradient(304deg,  nth($colors, 5) 0%,  nth($colors, 2) 100%);
    font-family: nth($font-family, 4);
    border-radius: 5px;
    font-size: 1rem;
    width: fit-content;
    transition: 0.2s all ease-in-out;
    font-weight: 600;
    border: none;
    cursor: pointer;
    animation: 1s enter;
    margin: 1rem;
    filter: grayscale(0);
  }
  
  .button2Show:hover {
    color: white;
    transform: translateY(1px);
    filter: grayscale(0);
  }
  
.row1gap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 1rem auto;
}
.BookSearcher {
    z-index: 100;
    border: none;
    padding: .5rem 2rem;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1.4rem;
    color: white;
    border-radius: 10px;
    filter: grayscale(0.5);
    background: nth($colors, 2);
background: linear-gradient(304deg, nth($colors, 2) 0%, nth($colors, 1) 100%);
    cursor: pointer;    
    transition: 0.5s all ease-in-out;
    margin: 0rem;
    width: 30%;
    box-sizing: border-box;
}

.BookSearcherOpen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    top: 0;
    left: 0;
    z-index: 2000;
    background: nth($colors, 2);
    background: linear-gradient(304deg, nth($colors, 2) 0%, nth($colors, 1) 100%);
}

.BookSearcherTittle {
    font-size: 1rem;
    background: none;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1.4rem;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .25rem;
    width: 100%;
}

.BookSearcher:hover {
    -webkit-filter: grayscale(0%); 
    filter: grayscale(0%);
}


.searcherInput {
    height: 1.5rem;
    width: 50%;
    margin: 0.1rem 0rem;
    font-size: 0.8rem;
    padding-left: 0.5rem;
    border-radius: 5px;
    border: none;
    font-weight: bolder;
    color: #576F72;
}

.searcherInput::placeholder {
    font-size: 0.7rem;
    font-family: 'Montserrat', sans-serif;
    justify-content: center;


}

.searcherButton {
    font-size: 0.7rem;
    font-family: 'Montserrat', sans-serif;
    border: none;
    background-color: nth($colors, 3);
    color: white;
    transition: 0.3s ease-in-out all;
    padding: 0.25rem;
    margin-left: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
}
.searcherButton:hover {
    background-color: nth($colors, 1);
}

.bookFoundedCont {
    background-color: rgb(255, 255, 255, 10%);
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: 0.8rem;
    padding: 0.5rem;
    border-radius: 5px;
    margin: 0.5rem;
}

.bookFoundedText {
    width: fit-content;
    margin: 0.1rem;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
}

.bookFoundedTextmini {
    width: fit-content;
    margin: 0.1rem;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    text-align: left;
    font-size: 0.8rem;
}

.closeButtonSearcher {
    font-family: 'Montserrat', sans-serif;
    border: none;
    border-radius: 5px;
    margin-left: 0.5rem;
    padding: .25rem .5rem;
    cursor: pointer;
  
}

.textbigger {
    font-size: 1.2rem;
}

.gradient {
    border-radius: 10px;
    position: relative;
    background-image: linear-gradient(
      to right,
      rgb(148, 148, 148),
      rgb(112, 112, 112)
    );
    z-index: 1;
  }
  
  .gradient::before {
    border-radius: 10px;
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(87,111,114,1),
      rgba(125,157,156,1)
    );
    z-index: -1;
    transition: opacity 0.5s linear;
    opacity: 0;
  }
  
  .gradient:hover::before {
    opacity: 1;
  }


.calendarMicrosite {
    z-index: 200;
    border-radius: 15px;
    margin: 0 auto;
}


.zindex {
    z-index: 200;
    padding: 3rem;
    border-radius: 15px;
}


/* GALLERY */

.galleryImgCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 20px;
    width: 100%;
    height: 30rem;
    margin: 0 auto;
    -webkit-tap-highlight-color: transparent;

}

.galleryImg {
    height: 100%;
    width: 100%;
    border-radius: 20px;
   object-fit: cover;
}

.loaderBar {
    position: absolute;
    margin: 0 auto;
    top: 1rem;
    height: 1rem;
    
}


.galleryLocation {
    position: absolute;
    margin: 0 auto;
    bottom: 1rem;
    right: 1rem;
    background-color: rgb(255, 255, 255, 20%);
    border-radius: 15px;
    padding: 0.5rem 1rem;
}

.galleryButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    opacity: 60%;
    -webkit-tap-highlight-color: transparent;

}

.galleryButton:hover {
    opacity: 100%;
}

.backButton { 
    position: absolute;
    top: 50%;
    left: 1rem;
    -webkit-tap-highlight-color: transparent;

}

.forButton {
    position: absolute;
    top: 50%;
    right: 1rem;
    -webkit-tap-highlight-color: transparent;

}

.galleryButtonText {
    font-size: 0.6rem;
    font-family: 'Montserrat', sans-serif;
    -webkit-tap-highlight-color: transparent;


}

/* OUR ROOMS AND MAILS */

.ourRoomsCont {
    display: flex;
    position: relative;
}

.ourRoomsButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: black;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    opacity: 60%;
    z-index: 100;
}

.ourRoomsButton:hover {
    opacity: 100%;
}

.ourRoomsbackButton { 
    position: absolute;
    top: 50%;
    left: -4rem;
}

.ourRoomsforButton {
    position: absolute;
    top: 50%;
    right: -4rem;
}


/* AMENITIES */
.amenitiesContCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70%;
    flex-wrap: wrap;
    margin: 0 auto;
}


.amenitiesCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    background-color: whitesmoke;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

.amenitiesImg {
    width: 1rem;
    height: 1rem;
}

.amenitiesText {
    margin: 0 0.5rem;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
}

.amenitiesDashBoardCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: fit-content;
    flex-wrap: wrap;
    margin: 3rem auto;

}


.amenitiesDashboardItemCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    background-color: whitesmoke;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    border: none;
}

.amenitiesDashboardItemCont:hover {
    background-color: #7D9D9C;
    color: white;
}

.dot {
    margin: 0 0.5rem;
}

.green {
    color: white;
    background-color: #7D9D9C;
}




/* COMMENT MODAL */ 


.commentListContDash {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
width: 50%;


}

.newcommentModal {
    display: flex;
    flex-direction: column;
    position: absolute;
    backdrop-filter: blur(20px);
    border-radius: 15px;
    align-items: center;
    justify-content: center;
z-index: 300;
top: 0;
width: 100%;
height: 100%;
}


.newCommentForm {
    display: flex;
    flex-direction: column;
}


.newcommentinput {
    width: 90%;
    padding: 0.2rem 0.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    margin: 0.1rem;
    background-color: nth($colors, 1);
    border: none;
    color: white;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    border-bottom: 1px white solid;
    opacity: 70%;
}
.newcommentinput::placeholder {
    color: white;

}

.newcommentinput:focus{
    opacity: 100%;
    outline: none
}

.newcommenttextarea {
    min-height: 5rem;
}


.newcommentClosebutton {
    position: absolute;
    top: 4rem;
    right: 4rem;
    width: fit-content;
    height: fit-content;
    padding: 0.25rem 0.5rem;
    margin: 1rem auto;
    border-radius: 5px;
    border: none;
    background-color: rgb(187, 151, 151);
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    color: white;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
    -webkit-tap-highlight-color: transparent;

}

.newcommentClosebutton:hover {
    background-color: rgb(192, 86, 86);

}

.newcommentsubmitbutton {

    width: fit-content;
    height: fit-content;
    padding: 0.25rem 0.5rem;
    margin: 1rem auto;
    border-radius: 5px;
    border: none;
    background-color: #576F72;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    color: white;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
    -webkit-tap-highlight-color: transparent;

}

.newcommentsubmitbutton:hover {
    background-color: #7D9D9C;

}


.newcommentsubmitbuttonLoaded {
    width: 2rem;
    height: 2rem;
    line-height: 1rem;
    padding: 0.5rem;
    margin: 1rem auto;
    border-radius: 50%;
    border: none;
    background-color: white;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    color: #576F72;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
    -webkit-tap-highlight-color: transparent;

}

.marginauto2 {
    margin: 1rem auto;
}
.displayNone {
    display: none;
}

.starsGroup {
    width: 90%;
    margin: 0.5rem;
}

  .starsGroup:hover > .star {
    color: orange;
  } 


.star {
    background: none;
    border: none;
    color: grey;
    font-size: 1.2rem;
}

  .star:hover {
    color: orange;
  }

  .star:hover ~ .star {
    color: grey;
  }

  .star:focus  ~ .star  {
    color: grey;
  }
  .star:focus {
    color: orange;
  }

  .starsGroup:focus {
    color: orange;
  } 
  

.margin {
margin-right: 1rem;
}

.positionabsolutetopright {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.white{ 
    color: white;
    margin: 0;
}

.black {
    color: black;

}
.accountDoesntExists {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10rem;
}

.ModalGallery {
    position: absolute;
    top:  1rem;
    left: -5.5rem;
    width: 150%;
    height: 100%;
    height: fit-content;
    transition: 0.3s all ease-in-out;
    z-index: 300;
    border-radius: 15px;
}

.openGalleryButton {
    z-index: 300;
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    top: 2rem;
    left: 2rem;
    background-color: white;
    border: none;
    opacity: 80%;
    padding: 00.25rem 0.5rem;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;
    color: black;
}




.leftB {
    padding: 0.25rem 0.85rem 0.25rem 0rem;
}

.rightB {
    padding: 0.25rem 0rem  0.25rem 0.85rem ;

}

.marginTop {
    margin-top: 2rem;
}

.wrap {
    flex-wrap: wrap;
}

.closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    opacity: 50%;
    background-color:  rgb(228, 141, 141);
    color: rgb(119, 27, 27) ;
    border: none;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    z-index: 1000;
}

.closeButton:hover {
    opacity: 100%;
}

.relativePos {
    position: relative;
}

.wfitcont {
    width: fit-content;
}

/* PRICING */

.pricingButtonTextCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #7D9D9C;
    padding: 0.5rem 1.5rem;
    margin: 0.2rem;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    border: none;
}

.pricingButtonTextCont:hover {
    background-color: #576F72;
    color: white;
}

.pricingButtonListCont {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1rem auto;
}

.pricingButtonCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.2rem;
    width: fit-content;
}

.UpAndDownCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;
}

.pricingUp, .pricingDown, .pricingNormal {
    width: 1.5rem;
    margin: 0.1rem;
    border-radius: 3px;
    border: none;
    transition: 0.3s all ease-in-out;
    cursor: pointer;

}

.pricingUp:hover {
    background-color: rgb(136, 186, 136);
    color: white;
}

.pricingDown:hover {
    background-color: rgb(205, 130, 120);
    color: white;
}

.pricingNormal:hover {
    background-color: rgb(144, 136, 186);
    color: white;
}

.pricingGreen {
    background-color: rgb(136, 186, 136);
    color: white;
}

.pricingRed {
    background-color: rgb(205, 130, 120);
    color: white;
}


.pricingBlue {
    background-color: rgb(144, 136, 186);
    color: white;
}

.bookConditionText {
    padding: 0.25rem;
    border-radius: 5px;
    margin-top: 0.5rem;
    border: none;
}

.cursorPointer {
    cursor: pointer;
}

.backButtonTransparent {
    position: absolute;
    width: 7rem;
    top: 1rem;
    left: 1rem;
}

.margin1rem {
    margin: 1rem;
}

/* BASES Y CONDICIONES */

.termsAndCondCont {
    width: 30rem;
    position: absolute;
    bottom: 2rem;
    padding: 0rem 2rem 2rem 2rem;
    border-radius: 10px;
    background-color: #576F72;
    z-index: 400;
    
}

.barlow {
    font-family: 'Barlow Condensed', sans-serif;

}

.logoFont {
    font-family: 'Raleway Dots', cursive;
}

.whatsappLogo {
width: 1.25rem;
aspect-ratio: 1/1;
margin: 0.5rem;
transition: 0.2s all ease-in-out;
cursor: pointer;
}

.footerRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 3rem;
    margin: 0;
    padding: 0rem 0;
    background: rgb(255,177,236);
    background: linear-gradient(180deg, rgba(255,177,236,1) 0%, rgba(135,143,226,1) 99%);
}

.footerPadding {
    padding: 2rem 0;
}

.footerColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-family: 'Barlow Condensed', sans-serif;
}

.footerRow2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    padding: 1rem 0;
    margin: 0rem;
    background: rgb(0,16,213);
background: linear-gradient(180deg, rgba(0,16,213,1) 0%, rgba(0,14,180,1) 99%);
}

.footerDivider {
width: 1rem;
}

.footerLogo {
    width: 4rem;
    margin: 0 .5rem;
    transition: 0.2s all ease-in-out;
    cursor: pointer;
    }

.whatsappLogo:hover {
 filter: drop-shadow(1px 1px 5px nth($colors, 2) ) ;
}

.igEmbed {
    width: 400px;
    height: 500px;
}

.maxwidth30 {
    max-width: 50%;
    margin: 0;
    padding: 0;
}

.fitcontent {
  width: fit-content;
}

.newRequestForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 50%;
    padding: 1rem;
    box-sizing: border-box;
    background-color: whitesmoke;
    margin-bottom: 4rem;
}

.requestInputCont {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    gap: 0.5rem;

}

.requestInput {
    width: 100%;
    height: 2rem;
    font-size: 1rem;
    padding: 0.5rem;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.requestLabel {
    width: 5%;
}

.exclamationMark{
    position: relative;
    bottom: 0.3rem;

}

/* MEDIA QUERY */

@media only screen and (min-width: 601px) {

    .none-desktop {
        display: none !important;
    }
 }

 @media only screen and (min-width: 600px) and  (max-width: 1200px) {


 }


 @media only screen and (min-width: 100px) and  (max-width: 600px) {

    .none-mobile {
        display: none !important;
    }

    
.newRequestForm {
    width: 90%;
}
 
    .commentListContDash {
        position: relative;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    
    }

    .igEmbed {
        margin: 2rem 0;
      
    }
    .section {
        height: fit-content;
    }

    .section2 {
        height: fit-content;
        padding: 2rem 0;
    }

    .columnMobile {
        display: flex;
        flex-direction: column;
    }

   
    .commentsCont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .commentCont {

        width: 80%;

    }

    
.sectionBlack {
    position: relative;
    display: flex;
    flex-direction: column;

    padding: 5rem 0 5rem;
    color: white;
    align-items: center;
    justify-items: center;
    background-color: black;

}



    footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 3rem 10%;
    }

    .footerTexts {
        margin: 2rem 0;
        width: 100%;
        align-items: center;
        flex-direction: column;
    }

    .footerWhatsappText {
        padding: 0rem rem;
    }

    .map {
        width: 100%;
    }

.alignCenterTopMap {

    margin-top: 2rem;
    margin-left: 0;
}

.micrositeDescription {
    font-family: nth($font-family, 6);
    padding: 0 10%;
    font-size: 1.2rem;
    z-index: 100;
    margin: 0 0 2rem 0;
}


.amenitiesContCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    flex-wrap: wrap;
    margin: 0 auto;
}

.amenitiesDashBoardCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;
    margin: 3rem auto;

}


.amenitiesCont {
    margin: 0.4rem;
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
}


.rowInv {
    flex-direction: column;
}

.roomsandmessCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.ourRoomsButton {
    background-color: white;
    padding: 0.5rem;
    border-radius: 50%;
    opacity: 90%;
}

.ourRoomsbackButton { 
    position: absolute;
    top: 50%;
    left: -1.5rem;
}

.ourRoomsforButton {
    position: absolute;
    top: 50%;
    right: -1.5rem;
}


.w80 {
    width: 80%;

}

.servicesButton {
    width: 85%;
}

.micrositeCover {
padding: 1rem 0;

}
.bluegradient2 {
    width: 85%;
}

.footerRow {

    flex-direction: column;

    gap: 0rem;
    margin: 0;
    padding: 0rem 0;
 
}

.footerMap{
    width: 100%;
    height: 400px;
}

.footerRow2 {
    flex-direction: column;
    padding: 4rem .5rem;
    gap: .5rem; 
    box-sizing: border-box;
}

.footerTextRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .25rem;
    width: 100%;
    font-family: nth($font-family, 4);
    font-size: .8rem;
    margin: 0;
}

.footerSubColumn {
    flex-direction: column-reverse;
    gap: 5rem;
    box-sizing: border-box;
    width: 100%;
    padding: 5rem 1rem;
    
}

.inverseText {
    display: inline-block;
   transform: rotate(180deg);
position: relative;
top: .25rem;
left: 0.08rem;
}

.row1gap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .1rem;
    margin: 1rem auto;
}

.BookSearcher {
    width: 85%;
}

.BookSearcherOpen {
    box-sizing: border-box;
    width: 100vw;

}

}