

@import '../functions/list';

.black {
    color: black;
}

.dates {
    position: absolute;
    padding: 2rem;
    top: 0;
    background-color: black;
    color: white;
}

.newCalendarCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    height: fit-content;
}

.newCalendarCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    width: 100%;
    gap: .5rem;
}

.checkoutLabel {

    padding: 1rem 0;

    transition: 0.2s all ease-in-out;
    margin: 0.25rem;
    width: 98%;

    text-align: center;
    font-family: monospace;

    color: black;
    transition: 0.2s all ease-in-out;
    background: linear-gradient(304deg,  rgb(246, 246, 246) 0%,  rgb(255, 255, 255) 100%);
    font-family: nth($font-family, 4);
    border-radius: 10px;
    border: 1px solid rgb(213, 213, 213);
    font-weight: 400;

}

.w20rem {
    width: 20rem;
    padding: 1rem 0;
}



.dayBackg {
  font-style: italic;

}

.checkouInput {
    color: #181818;
    padding: .5rem 0;
    border: 1px solid #181818;
    transition: 0.2s all ease-in-out;
    margin: 0 0.5rem;
    width: 20rem;
    font-size: 1.2rem;
    text-align: center;
    font-family: monospace;
    cursor: pointer;
    border-radius: 0;
}


/* MEDIA QUERY */

@media only screen and (min-width: 601px) {

    .none-desktop {
        display: none !important;
    }
 }

 @media only screen and (min-width: 600px) and  (max-width: 1200px) {


 }


 @media only screen and (min-width: 100px) and  (max-width: 600px) {

    .none-mobile {
        display: none !important;
    }


    .newCalendarCont {
       margin: 5rem auto;
    }
    
}