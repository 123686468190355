@import '../functions/list';

.progressBarCont{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.stepCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0.2rem;
}

.step {
    color: white;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    background-color: rgb(196, 196, 196);
    transition: 0.3s all ease-in-out;
}

.stepDone {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    color: rgb(2, 94, 37);
    background-color: rgb(139, 201, 143);
    transition: 0.3s all ease-in-out;

}

.line {
    color: rgb(196, 196, 196);
}
.verde {
    transition: 0.3s all ease-in-out;
    color: rgb(139, 201, 143);
}