@import '../functions/list';

.dashboardColumnCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    height: 100%;
    margin: 1rem auto;
}

.dashboardRow20 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 25%;
}

.dashboardRowCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 90vh;
    overflow: auto; 
    gap: 0.5rem;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.DashboardSectionCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem;
    border-radius: 10px;
    margin: 0.2rem;
    background-color: whitesmoke;
    align-items: center;
    justify-content: flex-start;

}

.DashboardRoomsCont {
    display: flex;
    flex-direction: column;
    width: 62.3rem;
    height: 100%;
    padding: 1rem;
    border-radius: 10px;
    margin: 0.2rem;
    background-color: whitesmoke;
    align-items: center;
    justify-content: center;
}

.DashboardTitles {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
}

.nonewdata {
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    opacity: 50%;
    background-color:  rgb(228, 141, 141);
    color: rgb(119, 27, 27) ;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    transition: 0.3s all ease-in-out;
}

.nonewdata:hover {
    opacity: 100%;
}

.nonewdatamini {
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    opacity: 50%;
    background-color:  rgb(228, 141, 141);
    color: rgb(119, 27, 27) ;
    padding: 0.2rem 0.4rem;
    border-radius: 5px;
    transition: 0.3s all ease-in-out;
    font-size: 0.6rem;
    margin: 0.2rem;
}

.dashboardloader {
    padding: 1rem 2rem;
    background-color: white;
}


/* Arrivals Item */ 

.ArrivalsItemCont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;

}

.ArrivalsItem {
    width: 30%;
    display: flex;
    flex-direction: column;
    background-color:white;
    color: black;
    border-radius: 5px;
    margin: 0.2rem;
    align-items: center;
    justify-content: center;
    border-bottom: 10px nth($colors, 6) solid;
}

.dateTextDash {
    font-size: 0.75rem;
    font-family: 'Montserrat', sans-serif;
    margin: 0.1rem 0;
    font-weight: 500;
}

.margintop {
    margin-top: 1rem;
}

.roomswidth {
    width: 20%;
}

.ArrivalsName {
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 0.5rem 0;
    border-radius: 5px 5px 0 0;
    text-align: center;
    margin-bottom: 0.2rem;
    width: 100%;
    color: white;
    background-color: nth($colors, 6);
}

.ArrivalsButtonsCont {
    margin-top: 1rem;
}


.actionImgArrivals {
    width: 1rem;
    height: 1rem;
    opacity: 100%;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
   
}



.actionImgArrivals:hover {
    transform: translateY(-1px);
}

.minitextLink {
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 100;
    height: fit-content;
    margin: 1rem 0rem;
    padding: 0.25rem 0.5rem;
    width: fit-content;
    color: black;
    background-color: white;
    transition: 0.3s ease-in-out all;
    font-size: 1rem;
    border-radius: 5px;
}

.minitextLink:hover {
    color: white;
    background-color: nth($colors, 1);
}

.linkArrivals {
    text-decoration: none;
    list-style: none;  
    opacity: 90%;  
    transition: 0.3s all ease-in-out;
    margin: 0rem 1rem;
}

.linkArrivals:hover {
    opacity: 100%;
}



/* NEW BOOKINGS */

.newbookingsBack {
    background-color: white;
}


/* MESSAGES */

.MessageText {
    background-color: #F0EBE3;
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 0.8rem;
    padding: 0.4rem;
    border-radius: 5px;
}

.holdeFont {
    font-family: 'Wire One', sans-serif;
    font-size: 2rem;
    font-weight: lighter;
}





// WIDGET CONT

.bookingsWidgetCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.05rem;
    width: 10rem;
    padding:  1rem;
    height: 8rem;
    background-color: nth($colors, 3);
    border-radius: 5px;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    overflow: auto;
}


.BookingsWidgetItemCont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    gap: 0.05rem;
    width: 90%;
    font-size: 0.6rem;
    color: white;
    background-color: 
    rgb(128, 128, 128, 50%);
    padding: .25rem;
    border-radius: 2px;

}

.emptybookingsWidgetCont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    gap: 0.5rem;
    width: 8rem;
    padding:  1rem;
    height: 8rem;
    background-color: nth($colors, 7);
    border-radius: 5px;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;

}

.dashboardWidgetsCont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.gap {
    gap: 0.5rem;
}

/* MEDIA QUERY */

@media only screen and (min-width: 601px) {

    .none-desktop {
        display: none !important;
    }
 }

 @media only screen and (min-width: 600px) and  (max-width: 1200px) {


 }


 @media only screen and (min-width: 100px) and  (max-width: 600px) { 

    .dashboardRowCont {
        flex-direction: column;
        width: 100%;
    }

    .DashboardRoomsCont {
        width: 90%;
    }

    .DashboardSectionCont {
        width: 90%;
        height: fit-content;
    }

    .dashboardCont {
        margin-top: 4rem;
    }

    .ArrivalsItem {
        width: 45%;
    }

    .nonewdata {
        font-size: 0.7rem;
    }

    .dashboardWidgetsCont {
        flex-direction: column;
    }
 }