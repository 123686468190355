@import '../functions/list';
.backwardsbutton {
    color: white;
    text-align: center;
    align-items: center;
    background-color: #576F72;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    margin: 0.5rem auto;
    transition: 0.5s ease-in-out;
    text-decoration: none;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    font-family: 'Barlow Condensed', sans-serif;
    transition: ease-in-out 0.3s all;
    animation: backmove 3s ease-in-out infinite ;
    height: fit-content;
}

.backwardsbutton:hover {
    background-color: #7D9D9C;
    color: white;

}



@keyframes backmove {
    0% {transform: translateX(-0.5rem);
        border-left: #7D9D9C 4px solid;
    }
    50% {transform: translateX(0rem);
        border-left: #576F72 4px solid;}  
    100% {transform: translateX(-0.5rem);
        border-left: #7D9D9C 4px solid;}
}