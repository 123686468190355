@import '../functions/list';


.itemsEditInputsCont {
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
width: 100%;
position: relative;
gap: 0.2rem;
}



.itemsEditInput {
    font-family: nth($font-family, 3);
    color: nth($colors, 1) ;
    font-weight: bold;
    background-color: nth($colors, 2);
    border: 1px white dotted;
    margin: 0;
    padding: .25rem;
    font-size: .75rem;
    transition: 0.2s all ease-in-out;
    border-radius: 5px;

}

.itemsEditInputSub {
    position: absolute;
    bottom: -3.5rem;
    z-index: 10000;

}

.itemsEditNewButton{
    font-family: nth($font-family, 3);
    color: nth($colors, 1) ;
    font-weight: bold;
    background-color: nth($colors, 2);
    border: 1px white dotted;
    margin: 0.2rem;
    padding: 1rem;
    transition: 0.2s all ease-in-out;
    border-radius: 5px;

}

.w30rem {
    width: 30rem;
}
.addNewItemButton {
    width: 20rem;
    margin: 0.2rem auto;
    transition: 0.2s all ease-in-out;
    cursor: pointer;
    background-color: nth($colors, 5);
}

.addNewItemButton:hover {
    background-color: nth($colors, 4);
}
.alignCenter2 {
    text-align: center;
}

.bgreen {
    background-color: nth($colors, 5);
}
.bgEditing {
    background-color: nth($colors, 4);
}

.w5itemsrem {
    width: 5rem;
}


/* MEDIA QUERY */

@media only screen and (min-width: 601px) {

    .none-desktop {
        display: none !important;
    }
 }

 @media only screen and (min-width: 600px) and  (max-width: 1200px) {


 }


 @media only screen and (min-width: 100px) and  (max-width: 600px) {

 .w30rem {
    width: 10rem;
    height: fit-content;
 }

 
 .itemsEditInputsCont {
    overflow: auto;
    width: 100vw;
    }



    .itemsEditInput {
        width: 5rem;
    }

    .w5itemsrem {
        width: 2rem;
    }

    .addNewItemButton {
        width: 10rem;
    }

    .wMobile {
        width: 60%;

    }
}