@import '../functions/list';

.metricsCont {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: flex-start;
    overflow: hidden;
    height: 100vh;
    position: relative;
}


.rowOfCharts {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 80%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.rowOfCharts::-webkit-scrollbar {
    width: 1px;
  }
  
  .rowOfCharts::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 8px;
  }
  
  .rowOfCharts::-webkit-scrollbar-track {
    -webkit-box-shadow: #ffffff 0px 0px 5px inset;
            box-shadow: #ffffff 0px 0px 5px inset;
    border-radius: 10px;
  }
  

.lineChart {
    width: 45%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 3rem;
    position: relative;

}


.pieChart {
    width: 25%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin: 3rem 1rem 1rem 1rem;
    position: relative;
}

.biggerText {
    font-size: 1rem;

}

.sectionTittle {
    font-family: nth($font-family, 4); //montserrat
    font-weight: 300;
    color: nth($colors, 1);
    padding: 0 20%;
    font-size: 2rem;
    z-index: 100;
    margin: 1rem auto 1rem;
    font-weight: bolder;
    width: fit-content;
}

.chartTittle {
    font-family: nth($font-family, 4); //montserrat
    font-weight: 300;
    padding: 0 20%;
    font-size: 1.2rem;
    z-index: 100;
    margin: 0 0 1rem 0;
}

.buttonMetrics {
    position: absolute;
    top: 2%;
    right: 0%;

    padding: 0.25rem 0.5rem;
    color: nth($colors, 1);
    background: nth($colors, 5);
  background: linear-gradient(304deg,  nth($colors, 5) 0%,  nth($colors, 2) 100%);
    font-family: nth($font-family, 4);
    border-radius: 5px;
    font-size: 0.5rem;
    width: fit-content;
    transition: 0.2s all ease-in-out;
    margin: 0.5rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    animation: 1s enter;
  }