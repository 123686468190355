@import '../functions/list';

.agendaSectionCont {
    display: flex;
    flex-direction: row;
    width: 100%;
}



.emailCont {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: nth($colors, 3);
    color: white;
    width: 60%;

}

.emailcontTittle {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 2rem;
    z-index: 100;
    font-weight: 400;

}

.emailContText {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    z-index: 100;
    padding: 0 10%;
}

.emailwhiteBack {
background-color: white;
background-color: nth($colors, 1);
width: fit-content;
padding: 0.5rem 1rem;
margin: 1rem auto;
font-weight: bold;
border-radius: 5px;
}

.agendaListCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50rem;    
    flex-wrap: nowrap;
    overflow-y:visible;
    overflow-x:hidden;
    width: 50%;
    padding-top: 4rem;
}
.agendaListCont::-webkit-scrollbar {
    width: 0.5rem;
}
.agendaListCont::-webkit-scrollbar-track {
    background-color:  nth($colors, 3);
}
.agendaListCont::-webkit-scrollbar-thumb {
    background-color: nth($colors, 1);
}

.agendaLetterCont {
    width: 20rem;
    margin: 0.2rem;
    height: fit-content;

}
.abcletter {
    font-size: 2rem;
    color: black;
    background-color: whitesmoke;
    padding: 0.5rem;
    font-family: 'Barlow Condensed', sans-serif;
    width: fit-content;
    margin: 0.5rem auto;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
    width: 100%;
}

.abcletter:hover {
 
    background-color: 
    nth($colors, 6);
    color: white;
}

.agendaItemCont {
    display: flex;
    flex-direction: row;
    border-bottom: 1px whitesmoke solid;
    align-items: center;
    justify-content: center;
    margin: 0.5rem auto;
    padding: 0.5rem;
}

.contactName {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    margin: 0.5rem;
    padding: 00.5rem;
    border-radius: 5px;
    transition: 0.3s ease-in-out all;
    cursor: pointer;
}

.actionImgAgenda {
    width: 1rem;
    opacity: 50%;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    aspect-ratio: 1/1;
}

.agendaInputCont {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0rem auto;
}

.agendainput {
    width: 90%;
    padding: 1rem 0.5rem;
    height: fit-content;
    line-height: 1.5rem;
    margin: 0.1rem;
    background-color: nth($colors, 6);
    border: 2px solid white;
    border-radius: 5px;
    color: white;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    border-bottom: 1px white solid;
    opacity: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.agendainput::placeholder {
    color: white;
}

.agendainput:focus{
    opacity: 100%;
    outline: none
}

.agendaLabel {
    color: white;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 0.8rem;
    background-color: nth($colors, 1);
    text-align: left;
    position: relative;
    top: 1.5rem;
    left: 2rem;
    padding: 0.25rem 0.5rem;
    z-index: 300;
    width: fit-content;
    border-radius: 5px;
}

.emailsSelected {
    margin: 0.1rem;
    font-size: 0.8rem;
    width: fit-content;
}


.repeated {
    background-color:  rgb(228, 141, 141);
    color: rgb(119, 27, 27) ;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    position: absolute;
    top: 2rem;
    left: 50%;
    margin: 0 auto;
    animation: forbiddenDayEf normal 3s ease-in-out;

}


.moveUp {
    top: 0.75rem;
}

.emailImg {
    aspect-ratio: 1/1;
    width: 50%;

}

.newemailbutton {

    width: fit-content;
    height: fit-content;
    padding: 0.5rem 1rem;
    margin: 1rem;
    border-radius: 5px;
    border: none;
    background-color: #576F72;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    color: white;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
}

.newemailbutton:hover {
transition: 0.3s ease-in-out all;
   animation: backgg normal 3s ease-in-out;

}


/* EFFECTS */

@keyframes backgg {
    0% {        background-color: #576F72;
    }

    30% {        background-color: #8a759d;
    }   

    80% {        background-color: #576F72;
    } 

    100% {        background-color: #8a759d;
    }
}

.marginauto {
    margin: 2rem auto;
}

.plusText {
    filter: invert(1);
    width: 1rem;
    height: 1rem;
    aspect-ratio: 1/1;
    margin: auto 1rem;
}

.margintop {
    margin-top: 5rem;
}



/* MEDIA QUERY */

@media only screen and (min-width: 601px) {

    .none-desktop {
        display: none !important;
    }
 }

 @media only screen and (min-width: 600px) and  (max-width: 1200px) {


 }


 @media only screen and (min-width: 100px) and  (max-width: 600px) {

    .none-mobile {
        display: none !important;
    }

    .agendaSectionCont {
        flex-direction: column;
        width: 100%;
    }

    .emailCont {
        width: fit-content;
    }

    .columnQuery {
        display: flex;
        flex-direction: column;
    }

    .agendaLetterCont {
        width: 100%;
    }

    .abcletter {
        width: 100%;
    }

    .agendaListCont {
        width: 100%;
    }

    .agendaInputCont {

        width: 90%;
   
    }
 
}
