@import '../functions/list';

.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    font-family: 'Wire One', sans-serif;
    font-size: 3rem;
    margin: 0.5rem 0;
    padding: 0.2rem 0.5rem;
    width: max-content;
}

.logoback {
    color: white;
    background-color: #576F72;
    padding: 0rem 0.5rem;
    border-radius: 5px;
    margin: 0rem 0.4rem;
}

.sectionTittles {
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 100;
    margin: 1rem 0rem;
    padding: 0.2rem 0.5rem;
    width: fit-content;
    color: black;
    transition: 0.3s ease-in-out all;
    font-size: 1rem;
    position: absolute;
    top: 0rem;
    right: 1rem;
}


.dashboardCont {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: left;
}




.booklistcontAdmin {
    margin: 5rem auto;
    width: 100%;
}

.inputCont {
    display: flex;
    flex-direction: row;
    background-color: #cecece;
    margin: 0.5rem auto;
    padding: 0rem 0.3rem 0rem 0.5rem;
    border-radius: 0 5px 5px 5px;
    border: rgb(255, 255, 255, 20%) 1px solid;
    width:  80%;
    height: 2rem;
    align-items: center;
}


.label {
    margin-right: 2rem;
    font-size: 0.7rem;
    letter-spacing: 0.2rem;
    width: 15%;
    text-align: left;
}

.input2 {
    background-color: white;
    color: black;
    border: none;
    padding-left: 0.5rem;
    margin: 2rem;
    width: 80%;
    justify-items: space-between;
}

.input2::placeholder {
    color: rgb(160, 160, 160);
}



/* DASHBOARD */

.dashboardBookOrder {
    display: flex;
    flex-direction: column;
    width: 20rem;
    height: fit-content;
    padding: .5rem;
    margin: 0.5rem;
    align-items: center;
    transition: 0.3s all ease-in-out;
    border-radius: 10px;
    background: #ffffff;
    box-shadow:  5px 5px 20px #eeeeee,
    -5px -5px 20px #ffffff;

}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10rem;
}

.dateData {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-items: space-around;
    margin: 0.5rem 0rem;
}

.dataColumn {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 1rem;
    width: 50%;
}

.minitext {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0.5rem;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 0.8rem;
}



.dashtext {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0.5rem;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
}

.bookDataTopLeft {
    width: 50%;
}

.bookDataTop {
    color: white;
    background-color: nth($colors, 1);
    display: flex;
    flex-direction: row;
    width: 90%;
    padding: 0rem 1rem;
    border-radius: 10px;
    margin: 0.5rem;
    align-items: center;
    text-align: center;
    transition: 0.3s all ease-in-out;
}

.requestDataTop {
    color: white;
    background-color: nth($colors, 1);
    display: flex;
    width: 100%;
    padding: 0rem 1rem;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0.5rem;
    align-items: center;
    text-align: center;
    transition: 0.3s all ease-in-out;
}


.cancelled {
    background: rgb(156, 156, 156);
    transition: 0.3s all ease-in-out;

}

.dateTextCont {
    display: flex;
flex-direction: column;
margin-left: 1rem;

}

.dateText {
    display: flex;
    flex-direction: row;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 0.7rem;
    width: 10rem;
    height: fit-content;
    margin: 0rem;
}

.clientText{
    display: flex;
    flex-wrap: wrap;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 2rem;
    font-weight: bolder;
    text-align: left;

}

.clientTextRequest{
    display: flex;
    flex-wrap: wrap;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 2rem;
    margin: 1.5rem auto;
    font-weight: bolder;
    text-align: center;

}




.fromtoText {
    width: max-content;
    margin: 0.2rem 0rem 0.2rem 0.2rem;
    border-radius: 5px 0 0 5px;
    color: black;
    background-color: whitesmoke;
    font-family: 'Montserrat', sans-serif;
}


.fromtoDetPanel {
    margin: 0.2rem  0rem;
    width: max-content;
    background-color: darkslategrey;
    border-radius:5px;
    color: white ;
    font-family: 'Montserrat', sans-serif;    
    padding: 0.2rem 0.5rem;
}

.ImgPanel {
    filter: invert(0.75);
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

.in {
    padding: 0.2rem 0.4rem;
    color: rgb(152, 180, 152);
    border-radius: 5px;
}

.out {

    padding: 0.2rem 0.4rem;
    color: rgb(224, 182, 182);
    border-radius: 5px;

}


.switch {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 1.8rem;
    border: none;
    height: 1.2rem;
    padding: 0rem 1rem;

    transition: 0.3s all ease-in-out;
    cursor: pointer;
    margin-left: 0.5rem;
   
}

.switchText {
    font-size: 0.7rem;
    color: rgb(228, 141, 141);
    position: absolute;
    width: 15rem;
    margin-top: 5rem;
    z-index: 100;
    background-color: rgb(119, 27, 27);
    padding: 0.35rem;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
}

.on {
    background-color: rgb(23, 122, 64);
    color: rgb(141, 228, 174);
}

.off {
    background-color: rgb(119, 27, 27);
    color: rgb(228, 141, 141);
}

.pending {
    background-color: rgb(119, 110, 27);
    color: rgb(224, 228, 141);
}
.switchBallOff {
    display: flex;
    font-size: 1rem;
    position: relative;
    right: 0.3rem;
    bottom: 0.05rem;
  
}

.switchBallOn {
    font-size: 1rem;
    position: relative;
    left: 0.3rem;
    bottom: 0.05rem;
}

.switchBallCancelling {
    font-size: 1rem;
    position: relative;
    left: 0.3rem;
    bottom: 0.05rem;
    animation: deleting infinite 1s ease-in-out ;

}

@keyframes deleting {
    0% {     color: rgb(228, 141, 141);

    }

    50% {     color: rgb(141, 228, 174); 

    } 

    100% {     color: rgb(228, 141, 141);

    } 

}


.cancelBookButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0.5rem;
    padding: 0.2rem 0.5rem;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 0.8rem;
    background-color: rgb(119, 27, 27);
    color: rgb(228, 141, 141);
    border: none;
    border-radius: 5px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.cancelBookButton:hover {
    background-color: rgb(158, 65, 65);
}

.actionButton {
    font-size: 0.5rem;
    border: none;
    background: none;
}

.actionImg {
    width: 1.3rem;
    height: 1.3rem;
    opacity: 60%;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.actionImg:hover {
    opacity: 100%;
}

.cancelButton {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    background-color: rgb(119, 27, 27);
    color: rgb(228, 141, 141);
    width: 1.3rem;
    height: 1.3rem;
    line-height: 1.3rem;
    border-radius: 50%;
      border: none;
      font-weight: bolder;
}

.bookOrderDashboardlist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh;
    overflow: auto; 
}

.bookOrderDashboardlistCol {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 auto;
    align-items: center;
    justify-content: top;
    width: 100%;
}
.searchListCont {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}
.searchOrderCont {
    display: flex;
    flex-direction: row;
    height: 2rem;
    width: fit-content;
    align-items: center;
    margin: 0rem auto ;
    padding: 0.2rem 1rem;
    border-radius: 10px;
    border: 1px black solid;
}



.searchOrderInput {
    border: none;
    outline: none;
    font-family: 'Montserrat', sans-serif;    
    outline-offset: 1px;
}



.searchOrderButton {
    background: none;
    border: none;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.searchOrderButton:hover {
    transform: rotate(15deg);
}


.displayNone {
    display: none;
}

.invertFilter {
    filter: invert(1);

}


/* NAVBAR */

.navbarAllCont {
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.navbarPanelCont {
    position:static;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    margin: 0 1rem 0 0;
    height: max-content;
    width: max-content;
    padding: 1rem 1rem;
    transition: 0.3s all ease-in-out;
    background: whitesmoke;

    border-radius: 0 0 20px 0;
}

.supportCont {
    position:static;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    margin: 0;
    height: max-content;
    width: max-content;
    padding: 0rem 1rem;
    transition: 0.3s all ease-in-out;
    border-radius: 0 0 20px 0;
    color: #576F72;
    z-index: 200;
    
}

.column {
    display: flex;
    flex-direction: column;
}

.navbarList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.minitextnavbar {
    font-family: 'Montserrat', sans-serif;   
    font-weight: 500;
    margin: 0.5rem 0rem;
    padding: 0.2rem 0.5rem;
    width: fit-content;
    color: black;
    transition: 0.3s ease-in-out all;
    font-size: .8rem;
    letter-spacing: -0.025rem;
}

.minitextside {
    position: relative;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 100;
    margin: 0.5rem 0rem;
    padding: 0.2rem 0.5rem;
    width: fit-content;
    transition: 0.3s ease-in-out all;
    font-size: 1rem;
    animation: sideglow infinite 1s ease-in-out;
    bottom: 0rem;

}

@keyframes sideglow {
    0% {     
        color: #7D9D9C;

    }

    50% {      
        color: goldenrod;
        text-shadow: yellow 2px 2px 20px;

        bottom: 0.2rem;
    } 

    100% {     
        color: #7D9D9C;
    } 

}



.textnavbar {
    font-weight: 300;
    color: black;
    font-family: 'Montserrat', sans-serif;    
    margin: 0 0.2rem;
    font-size: 0.8rem;
    
}

.link {
    text-decoration: none;
    list-style: none;  
    opacity: 70%;  
    transition: 0.3s all ease-in-out;
    border-radius: 5px;
    padding: 0.0rem 0.5rem;
    z-index: 200;
    
}

.link:hover {
    opacity: 100%;
    background-color: nth($colors, 2);
}

.linkActive {
    opacity: 100%;
    background-color: nth($colors, 2);
}

.notiCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.notiText {
    font-family: 'Montserrat', sans-serif;    
    color: white;
    position: relative;
    right: 0rem;
    bottom: 0.6rem;
    margin: 0;
    animation: notif infinite 3s ease-in-out;
    font-size: 0.85rem;
    background-color: rgb(170, 79, 79);
    line-height: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
}

.navbarIconN {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    padding: 0.5rem;
    transition: 0.3s ease-in-out all;
}


/* EFFECTS */

@keyframes notif {
    0% {    opacity: 100%;
        transform: scale(1.1);
    }

    50% {     opacity: 20%;
        transform: scale(1);
    }   

    100% {     opacity: 100%;
        transform: scale(1.1);
    } 

}


.addBook {
    color: #576F72;
    background-color: white;
    font-size: 1.5rem;
    font-weight: bold;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.3rem;
    border-radius: 50%;
    margin: 1.5rem;
    transition: 0.5s all ease-in-out;
}

.addBook:hover {
    color: white;
    background-color: #576F72;

}

.navbarIcon {
    width: 1.2rem;
    height: 1.2rem;
    margin: 1.2rem;
    filter: invert(1);
    opacity: 60%;
    transition: 0.3s ease-in-out all;
}

.navbarIcon:hover {
    transform: translateY(2px);
    opacity: 100%;
}

.navbarText {
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;   
    text-decoration: none;
    color: black; 
    margin: 1rem 0.1rem;
    width: 7rem;
    transition: 0.3s all ease-in-out;
    padding: 0.3rem 0.1rem;
    border-right: 1px solid black;
}

.navbarText:hover {
    background-color: white;
    color: darkslategrey;
}

/* MESSAGES */

.messageTextCont {
    font-family: 'Montserrat', sans-serif;   
    width: 90%;
    font-size: 0.9rem;
    padding: 0rem 1rem;
    border-radius: 10px;
    margin: 0.5rem;
    align-items: center;
    text-align: center;
    background-color: whitesmoke;
}



.clientMessageText{
    display: flex;
    flex-wrap: wrap;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 2rem;
    font-weight: bolder;
    text-align: left;

}
.messageDataTopLeft {
    width: 70%;
}

.hourTextCont {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    width: 30%;
}

.hourTextContDash {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    width: 30%;
}


/* ROOMS CHECK IN CHECK OUT */


.guestinscont {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
}

.guestDetailCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    margin: 0rem auto;
    align-items: center;
    transition: 0.3s all ease-in-out;
    border-radius: 10px;
    background: #ffffff;
}

.checkListCont {
    display: flex;
    flex-direction: row;
    margin: 1rem auto;
    align-items: top;
    justify-content: center;
}

.roomCheckcont {
    margin: 1rem;
    width: 15rem;
    height: fit-content;
    padding: 1rem;
    color: white;
    background-color: #576F72;
    border-radius: 10px;
}

.roomCheckTitle {
    font-family: 'Barlow Condensed', sans-serif;

}



/* NEW GUESTS */


.guestDataTopLeft {
    width: 50%;
}

.guestDataTop {
    color: white;
    display: flex;
    flex-direction: row;
    background: #576F72;
    width: 90%;
    padding: 0rem 1rem;
    border-radius: 10px 10px 0 0;
    margin: 0.5rem;
    align-items: center;
    text-align: center;
    border-bottom: 4px #7D9D9C solid;
}


.newguestscontCont {
    display: flex;
    flex-direction: column;
}

.newguestscont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem;
    border-radius: 10px;
    width: fit-content;
    margin: 2rem auto;
    align-items: center;
    justify-content: center;
    color: white;
    background: #ffffff;
    box-shadow:  5px 5px 20px #eeeeee,
    -5px -5px 20px #ffffff;
}

.backwardsButtonNG {
width: 9rem;
margin-bottom: 1rem;
}


.newguestform {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    background-color: #576F72;
    padding: 1rem;
    border-radius: 5px;
}

.marginauto {
    margin: 5rem auto;
}
.newguestinput {
    width: 10rem;
    padding: 0.2rem 0.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    margin: 0.1rem;
    background-color: #576F72;
    border: none;
    color: white;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    border-bottom: 1px white solid;
    opacity: 70%;
}
.newguestinput::placeholder {
    color: white;

}

.newguestinput:focus{
    opacity: 100%;
    outline: none
}
.newguestsubmitbutton {
    width: 6rem;
    height: 1.5rem;
    padding: 0rem 0.5rem;
    margin: 1rem auto;
    border-radius: 5px;
    border: none;
    background-color: white;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    color: #576F72;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
}

.newguestsubmitbuttonLoaded {
    width: 2rem;
    height: 2rem;
    line-height: 1rem;
    padding: 0.5rem;
    margin: 1rem auto;
    border-radius: 50%;
    border: none;
    background-color: white;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    color: #576F72;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
}

.guestText{
    display: flex;
    flex-wrap: wrap;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1.2rem;
    font-weight: bolder;
    text-align: left;
}

.guesttitle{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1.2rem;
    font-weight: bolder;
 
}


.ngdateTextCont {
display: flex;
flex-direction: column;
text-align: left;
margin-left: 1rem;
width: 40%;
}

.guestdateText {
    display: flex;
    flex-direction: row;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 0.7rem;
    width: 8rem;
    height: fit-content;
    margin: 0rem;
}

.CheckInRoomsCont {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: center;
    margin: 2rem auto;
    justify-items: center;
    align-items: top;
}
.checkinRoomCont {
    min-width: 15rem;
    height: fit-content;
    padding: 1rem;
    background: #ffffff;
    box-shadow:  5px 5px 20px #eeeeee,
    -5px -5px 20px #ffffff;
    color: black;
    margin: 0.5rem;
    border-radius: 10px;

}

.checkinRoomCont-title {
    font-family: 'Barlow Condensed', sans-serif;
}


.checkInGuestsCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    margin: 1rem;
}

.buttonsCont {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}

.plusbutton {
    display: flex;
    align-items: center;
    justify-content: center;
height: 3rem;
width: 6rem;
font-size: 1.5rem;
border-radius: 5px;
color: white;
background-color: grey;
border: none;
font-family: 'Montserrat', sans-serif;
transition: 0.3s ease-in-out all;
cursor: pointer;
margin: 0.2rem;
border-bottom: 4px solid  #7D9D9C;
opacity: 50%;
}

.plusbutton:hover {
    background-color: #576F72;
    opacity: 100%;
}

.confbutton {
height: 3rem;
width: 6rem;
padding: 0rem 1rem;
font-size: 0.8rem;
border-radius: 5px;
color: white;
background-color: grey;
border: none;
font-family: 'Montserrat', sans-serif;
transition: 0.3s ease-in-out all;
cursor: pointer;
margin: 0.2rem;
border-bottom: 4px solid  #7D9D9C;
opacity: 50%;
}
.confbutton:hover {
    background-color: #576F72;
    opacity: 100%;
}

.opacity100 {
    opacity: 100%;
    font-weight: bold;
    background-color: #576F72;
}
.guestdetailbutton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    background: #576F72;
    border: none;
    border-radius: 5px;
    width: 3rem;
    height: 1.5rem;
    margin: 0.5rem;
    font-size: 1.2rem;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    transform:rotate(90deg);
}

.guestdetailbutton:hover {
    color: #7D9D9C ;

}

.leftBar {
    position: relative;
    left: 0rem;
    transform:rotate(0deg);
    transition: 0.3s ease-in-out all;
    
}

.rightBar {
    position: relative;
    right: 0rem;
    transform:rotate(-0deg);
    transition: 0.3s ease-in-out all;

}

.leftBarOpened {
    position: relative;
    left: 0.2rem;
    transform:rotate(45deg);
    transition: 0.3s ease-in-out all;

    
}

.righBarOpened {
    position: relative;

    right: 0.25rem;
    transform:rotate(-45deg);
    transition: 0.3s ease-in-out all;

}

.newcheckinbutton {
    color: white;
    background: #576F72;
    border: none;
    border-radius: 5px;
    height: 2rem;
    margin: 0.5rem;
    padding: 0 0.5rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.newcheckinbutton:hover {
    color: white;
    background: #7D9D9C;
}

.newcheckoutbutton {

    color:  white;
    border: none;
    border-radius: 5px;
    margin: 1rem 0rem 0.5rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.7rem;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    background-color: rgb(228, 141, 141);

}

.newcheckoutbutton:hover {
    background-color: rgb(119, 27, 27);
    color: rgb(228, 141, 141);
}

.checkedout {
    font-size: 0.8rem;
    border-radius: 5px;
    margin: 1rem auto 0.5rem;
    background-color: rgb(228, 141, 141);
    color: rgb(119, 27, 27);
    transition: 0.3s all ease-in-out;
    padding: 0.5rem 0.5rem;
    width: fit-content;

}

.checkoutTrashCan {
    width: 1.3rem;
    height: 1.3rem;

    padding: 0.4rem 0.2rem;
    filter: invert(1);
}


.guestdetailExtrainfo {
    color: white;
    background: #7D9D9C;
    width: 90%;
    padding: 1rem 1rem;
    border-radius: 0px 0px 10px 10px;
    position: relative;
    bottom: 0.5rem;
}

.guestDetailMiniText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    height: 1.4rem;
    margin: 0.1rem auto;
    border-radius: 2px;

}

.roomempty {
    border-radius: 5px;
    margin: 0 auto;
    padding: 0.5rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    background-color:  rgb(228, 141, 141);
    color: rgb(119, 27, 27);
}

.beds {
    color: white;
    background: #576F72;
    border: none;
    border-radius: 5px;
    margin: 0 auto;
    padding: 0.5rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    transition: 0.3s all ease-in-out;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.bedimg {
    width: 1rem;
    height: 1rem;
    filter: invert(1);
    margin: 0 0.5rem;
    color: white;
}

.bedtext {
    width: 1rem;
    height: 1rem;
    margin: 0 0.5rem;
    color: white;
}


.w100 {
    width: 100%;
}

.margintop {
    margin-top: 0rem;
}

.margintop2 {
    margin-top: 2rem;
}
/* PAYMENTS */

.paymentscont {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0rem auto;
}

.paymentItem {    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: fit-content;
    background-color: whitesmoke;
    padding: 1rem;
    margin: 1rem;
    transition: 0.3s ease-in-out all;
    border-radius: 10px;
    cursor: pointer;
}

.paymentItem:hover {
    background-color: rgb(236, 236, 236);

}

.mercadopagoTittle {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    padding: 2rem;
    margin-top: 5rem;
}


.profileTittle {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    padding: 2rem;
    
}

.paymentsText {
    font-size: 1rem;
    font-family: 'Barlow Condensed', sans-serif;
}

.paybutton {
    font-size: 0.8rem;
    padding: 0.5rem;
    font-family: 'Montserrat', sans-serif;
    background-color: nth($colors, 6);
    margin: 0.5rem;
    color:  white;
    border:  none;
    border-radius: 10px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;

}

.paybutton:focus {
    background-color: nth($colors, 1);
    border-bottom: 6px solid  nth($colors, 2);
}

.paybutton:hover {
    background-color: nth($colors, 1);
    border-bottom: 6px solid nth($colors, 2);
}

.paybuttonselected {
    background-color: nth($colors, 1);
    border-bottom: 6px solid nth($colors, 2);
}

.displaynone {
    display: none;
}

.w100 {
    width: 100%;
    height: fit-content;
}

.w50 {
    width: 50%;
    height: fit-content;  
}

.flexwrap {
    flex-wrap: wrap;
}


.micrositeGalleryEditCont {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.margintopNone {
    margin-top: 0;
}

.profileName {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
    text-transform: capitalize;
    padding: 2.5rem;
    color: white;
    background-color: #7D9D9C;
    border-radius: 50%;
    width: fit-content;
    height: fit-content;
    margin: 0rem auto 1rem;
}

.ww40 {
    width: 40%;
}

/* COMMENTS ROOM */

.commentListCont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2rem;
    position: relative;
}

.loginTittle {
    font-family: nth($font-family, 4); //montserrat
    font-weight: 300;
    color: nth($colors, 1);
    font-size: 3rem;
    z-index: 100;
    margin: 1rem auto 1rem;
    font-weight: bolder;
    width: fit-content;
}


.logincont {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5rem auto 0;
    width: fit-content;
}

.loginlogoAnim {
    animation: loginlogo 2s ease-in-out ;
    transition: 0.3s all ease-in-out;

}


.black {
    color: black;
}

.refreshButtonCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: nth($font-family, 4); //montserrat
    font-size: 0.8rem;
    padding: 0.3rem;
    aspect-ratio: 1/1;
    border-radius: 5px;
    border: none;
    width: fit-content;
    margin: 0 .5rem;
    background-color: nth($colors, 6);
    color: white;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
}
.refreshButtonCont:hover {
    background-color:darken($color: nth($colors, 6), $amount: 10)
}
.refresButtonImg {
    width: .8rem;
    height: .8rem;
}

.rotationF {
    animation: rotation 2s ease-in-out ;
}


.bgChangingRotationF {
    animation: bgChangingRotation 2s ease-in-out ;
}

@keyframes rotation {
    0% {     transform: rotate(0deg)}
    50% {      transform: rotate(359deg)} 
    100% {    transform: rotate(359deg)} 
}

@keyframes bgChangingRotation {
    0% {      background-color: nth($colors, 6);
    }
    25% {      background-color: nth($colors, 2);} 
    100% {     background-color: nth($colors, 6);
    } 
}



@keyframes loginlogo {
    0% {     opacity: 100%}
    50% {      opacity: 40%} 
    100% {     opacity: 100%} 
}

.loginbutton {
    color: white;
    background: #576F72;
    border: none;
    border-radius: 5px;
    height: 2rem;
    margin: 1rem;
    padding: 0 0.5rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.loginbutton:hover {
    color: white;
    background: #7D9D9C;
}


.errorLogin {
    background-color: rgb(238, 119, 119);
    color: white;
    transition: 0.3s all ease-in-out;
    animation: errorLog normal 2s ease-in-out;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 5px;
    z-index: 200;
}


@keyframes errorLog {
    0% {    opacity: 0%;
    }

    30% {     opacity: 100%;;
    }   

    80% {     opacity: 100%;;
    } 

    100% {     opacity: 0%;;
    }
}


.newPlanVideo {
    width: 90%;
    aspect-ratio: 16/9;
    border-radius: 10px;
    margin: 1rem;
}

.w40 {
    width: 40%;
}

.w60 {
    width: 60%;
}


/* PRICING */

.center2 {
   margin: 0 auto;

}


/* GALLERY IMAGES */

.imgGalleryDataBaseCont {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 3rem;
    position: fixed;
    top: 0.5rem;
    right: 2rem;
    background-color: #576F72;
    color: white;
    border-radius: 10px;
    width: 50rem;
    z-index: 500;

}

.imgGalleryImagesCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    
     
}

.imgGallery {
    width: 10rem;
    height: 10rem;
    aspect-ratio: 1/1;
    margin: 0.2rem;
    filter: grayscale(1);
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    
}

.imgGallery:hover{
    filter: grayscale(0);
    transform: scale(1.02);
}

.imgGallery:focus{
    filter: grayscale(0);
    transform: scale(1.02);
}


.imgGalleryTittle {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 2rem;
    padding-top: 0rem;
    z-index: 100;
    font-weight: 400;
    margin: 1rem 0rem 3rem;
}

.bigImgCont {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    position: absolute;
    background-color: rgb(238, 119, 119);
    color: white;
    transition: 0.3s all ease-in-out;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 5px;
    z-index: 200;
    bottom: 2rem;
}

.uploadFromData {
    position: absolute;
    background-color: red;

}

.uploadImgButtonCont {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 2rem;
    right: 15rem;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.uploadimageButton {
    width: 100%;
    margin: 2rem auto;
    font-size: 1rem;
    cursor: pointer;
    font-family: 'Barlow Condensed', sans-serif;
    background-color: #7D9D9C;
    color: white;
    padding: 0.5rem 1rem;
    z-index: 1000;
}

.reloadButtonCont {
    background: none;
    border: none;
}

.reloadButtonImg {
    filter: invert(1);
    width: 2rem;
    height: 2rem;
    aspect-ratio: 1/1;
    transition: 1s all ease-in-out;
    cursor: pointer;
}

.reloadButtonImg:hover {
    transform: rotate(359deg);
}


/* MEDIA QUERY */

@media only screen and (min-width: 601px) {

    .none-desktop {
        display: none !important;
    }
 }

 @media only screen and (min-width: 600px) and  (max-width: 1200px) {


 }


 @media only screen and (min-width: 100px) and  (max-width: 600px) {

  
  
    .dashboardCont {
        display: flex;
        flex-direction: column;
        align-items: top;
        justify-content: center;
    }
    
    .navbarPanelCont {
        position: absolute;
        flex-direction: column;
        top: 0;
        align-items: flex-start;
        margin: 0 0rem;
        width: 100%;
        padding: 6rem 1rem 15rem;
        transition: 0.3s all ease-in-out;
        z-index: 100;

    }

    .navbarmobilebutton {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0rem;
        top: 0.5rem;
        color: black;
        background: none;
        border: none;
        border-radius: 5px;
        width: 3rem;
        height: 1.5rem;
        margin: 0.5rem;
        font-size: 1.2rem;
        transition: 0.3s all ease-in-out;
        cursor: pointer;
        transform:rotate(90deg);
        z-index: 2000;
    }
    
    .navbarmobilebutton:hover {
        color: #7D9D9C ;
    
    }
    

    .logo {
        font-family: 'Wire One', sans-serif;
        font-size: 3rem;
        margin: 0.3rem 0.2rem;
        padding: 0.2rem 0.5rem;
        width: fit-content;
    }
    
    .logoback {
        color: white;
        background-color: #576F72;
        padding: 0rem 0.5rem;
        border-radius: 5px;
    
    }

    .dashboardBookOrder {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 90%;
        height: fit-content;
        padding: 1rem;
        margin: 1rem auto;
        align-items: center;
        transition: 0.3s all ease-in-out;
        border-radius: 10px;
        background: #ffffff;

    }
    
    .CheckInRoomsCont {
        display: flex;
        flex-direction: column;

    }


    .addBook {
        color: #576F72;
        background-color: white;
        font-size: 1.5rem;
        font-weight: bold;
        width: 2rem;
        height: 2rem;
        padding: 0.2rem 0.6rem;
        line-height: 1.7rem;
        border-radius: 50%;
        margin: 1rem;
        transition: 0.5s all ease-in-out;
    }

    .checkListCont {
        display: flex;
        flex-direction: column;
        margin: 1rem auto;
        align-items: center;
        justify-content: center;
    }

    .margintop {
        margin-top: 3rem;
    }

    .sectionTittles {
        font-family: 'Barlow Condensed', sans-serif;
        font-weight: 100;
        margin: 1rem 0rem;
        padding: 0.2rem 0.5rem;
        width: fit-content;
        color: black;
        transition: 0.3s ease-in-out all;
        font-size: 1rem;
        position: absolute;
        top: 0rem;
        left: 1rem;
    }
    
    .ww40 {
        width: 20rem;
    }

    .paymentItem {    

        height: fit-content;
        width: 90%;
    }


    .imgGalleryDataBaseCont {
        margin: 0 auto;
        padding: 1rem;
        position: relative;
        left: 0rem;
        width: 90%;
     
    
    }

    .imgGallery {
        width: 7rem;
        height: 7rem;
    }

    .imgGalleryTittle {
        font-size: 1.5rem;
    }
}