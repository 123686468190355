@import '../functions/list';


.roomsEditCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 10rem;
    height: 7rem;
    padding: 1rem;
    background: #ffffff;
    box-shadow:  5px 5px 20px #eeeeee,
    -5px -5px 20px #ffffff;
    color: black;
    margin: 0.5rem;
    border-radius: 10px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;

}

.paragraph {
  width: 90%;
  background-color: whitesmoke;
  font-size: 1rem;
  color: black;
  border: 10px;
  padding: 1rem;
  font-family: nth($font-family, 1);
}

.servicesListCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
}

.noShow {
  opacity: 0;
}


.roomsEditCont:hover {
    color: white;
    background-color: #576F72;

}

.roomeditCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem auto;
}

.center {
  align-items: center;
  justify-content: center;

  margin: 0 auto;
}

.w40 {
  width: 40%;
}

.roomsEditCont2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 10rem;
    height: 7rem;
    padding: 1rem;
    background: #ffffff;
    box-shadow:  5px 5px 20px #eeeeee,
    -5px -5px 20px #ffffff;
    color: black;
    margin: 0.5rem;
    border-radius: 10px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    opacity: 50%;
}

.roomsEditCont2:hover {
    opacity: 100%;
}


.addnewroomsEditCont-title {
    font-size: 2rem;
    font-weight: bolder;
    margin: 0;
    color: white;
    text-decoration: none;
    background: none;
    
}
.roomsEditCont-title {
    font-family: 'Barlow Condensed', sans-serif;
    text-decoration: none;

}


.greenBack3 {
    position: relative;
    color: white;
    background-image: linear-gradient(
      to right,
      rgb(71, 71, 71),
      rgb(112, 112, 112)
    );
    z-index: 1;
    border-radius: 15px;
  }
  
  
  .greenBack3::before {
    border-radius: 15px;

    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(87,111,114,1),
      rgba(125,157,156,1)
    );
    z-index: -1;
    transition: opacity 0.5s linear;
    opacity: 0;
    animation: headerEffect infinite 5s ease-in-out ;
  }




  /* EDITING ROOM */
  
  
  .RoomEditform {
      display: flex;
      flex-direction: column;
      margin: 1rem;
      background-color: whitesmoke;
      padding: 3rem 2rem;
      border-radius: 5px;
      margin: 0 auto;
      height: fit-content;
  }
  

  .inputRoomEditLabelCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: black;
  }

  .labelRoomEdit {
    width: 8rem;
    text-align: left;
    font-size: 0.8rem;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .labelw100 {
    width: 100%;
  }
  .RoomEditInput {
    width: 80%;
    padding: 0.2rem 0.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    margin: 0.2rem;
    background-color: white;
    border: none;
    color: grey;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    transition: 0.3s all ease-in-out;
    opacity: 70%;
}
.RoomEditInput::placeholder {
    color: white;

}

.RoomEditInput:focus{
    opacity: 100%;
    outline: none;
    background-color:#7D9D9C;
    color: white;
    border-radius: 5px;
}

.textareainput {
  min-height: 8rem;

}

.roomsedittitle{
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  margin: 0 0 2rem 0;
  color: black;
}

/* BUTTONS */


.buttonEditCont {
 margin: 0 1rem 0 0.5rem;
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 position: relative;
}


.editButtonText {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 0.8rem;
  border-radius: 5px;
  border: none;
  padding: 0.2rem 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  width: 4rem;
}

.editButtonText:hover {
  background-color: #7D9D9C;
  color: white;
}

.editDone {
  position: absolute;
  left:5rem;
  color: nth($colors, 1);
  box-shadow: 1px 1px 2px nth($colors,2 );
}


.RoomsEditImgCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  
}

.imgEditCont {
  position: relative;
  margin: 0.5rem;
}

.removeImgButton {
  display: flex;
  position: absolute;
  flex-direction: row;
  top: -0.5rem;
  right: -0.5rem;
  transition: 0.3s ease-in-out all;
  width: 1.3rem;
  height: 1.3rem;
  line-height: 1.2rem;
  border-radius: 50%;
  margin: 0.2rem;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Barlow Condensed', sans-serif;
  background-color: rgb(238, 119, 119);

}

.removeImgButton:hover {
  background-color: rgb(78, 11, 11);
  transform: translateY(-1px);
}

.deleteText {
  display: flex;
  position: absolute;
  top: -0.5rem;
  right: 0rem;
  border-radius: 3px;
  border: none;
  margin: 0.2rem;
  padding: 0.25rem 0.5rem;
  font-family: 'Barlow Condensed', sans-serif;
  color: rgb(78, 11, 11);
  background-color: rgb(238, 119, 119);
  transition: 0.3s ease-in-out all;
  cursor: pointer;

}

.deleteText:hover {
  background-color: rgb(78, 11, 11);
  color: white;
}

.crossImg {
  width: 1.2rem;
  height: 1.2rem;
  aspect-ratio: 1/1;
  filter: invert(1);
  transition: 0.3s ease-in-out all;

}

.crossImg:hover {
  transform: rotate(179deg);
}

.editRoomImg {
  width: 10rem;
  height: 10rem;
  border-radius: 5px;
  object-fit:cover;
}

.imgbuttonCont {
  position: relative;
  display: flex;
  flex-direction: row;
}

.addimageButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  border-radius: 5px;
  background-color: #7D9D9C;
  color: #576F72;
  opacity: 70%;
  transition: 0.3s all ease-in-out;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 2rem;
  cursor:cell;
}

.addimageButton:hover {
  opacity: 100%;
  }


  .addimageButton2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 3rem;
    border-radius: 5px;
    background-color: #7D9D9C;
    color: #576F72;
    opacity: 70%;
    transition: 0.3s all ease-in-out;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    cursor:cell;
    border: none;
    margin: 0.5rem;
    z-index: 2000;
  }
  
  .addimageButton2:hover {
    opacity: 100%;
    }

.addimageButtonAbs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.5rem;
  height: 3rem;
  border-radius: 5px;
  background-color: #7D9D9C;
  color: #576F72;
  transition: 0.3s all ease-in-out;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 1rem;
  cursor:cell;
  z-index: 3000;
 position: absolute;
 right: 0;
  top: 0;
  opacity: 0;
}

.imgSeparador {
  margin: 2rem;
  width: 2rem;
  aspect-ratio: 1/1;
}


.imgTittleText {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 1rem;
}


.uploadImg {
  filter: invert(1);
  width: 2rem;
  height: 2rem;
}

.confirmationButton {
  background: none;
  border: none;
  border-radius: 5px;
  background-color: rgb(255, 255, 255, 20%);
  padding: 0.2rem 0.4rem;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 1.5rem;
  color: grey;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.confirmationButton:hover {
  color:#576F72;
  background-color: rgb(255, 255, 255, 60%);
}


.addRoomButton {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  padding: 0.2rem 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  width: fit-content;
  margin: 1rem auto;
}

.addRoomButton:hover {
  background-color: #7D9D9C;
  color: white;
}


.roomitem {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  width: fit-content;
  margin: 0.25rem;
  padding: 0.25rem 0.5rem;
  color: white;
  background-color: #576F72;
}


.buttonMonths {
  background: none;
  border: none;
  margin: 0.5rem auto;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  width: fit-content;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  background-color: #576F72;
  color: white;
}

.buttonMonths:hover {
  background-color: #7D9D9C;
  color: white;
}

.monthItem {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  padding: 0.25rem;
  background-color: #7D9D9C;
  color: white;
  margin: 0.2rem;
  width: 1rem;
}



.widthFitCont {
  width: 2rem;
}

.sectionPanelCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem auto;
  
}


.schedulesCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50vw;
  flex-wrap: wrap;
  
}

.scheduleButton {
  padding: 0.25rem 0.5rem;
  border: none;
  background-color: nth($colors, 6);
  color: white;
  border-radius: 10px;
  margin: 0.1rem;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}

.scheduleButton:hover {
  background-color: nth($colors, 1);
}

.greyBack {
  background-color: nth($colors, 2);
}

.serviceEditCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 3rem;
  font-family: nth($font-family, 4);
  transition: 0.5s all ease-in-out;
  background-color: whitesmoke;
  height: 10rem;
  aspect-ratio: 1/1;
  text-decoration: none;
  color: black;
  border-radius: 10px;
  margin: 1rem;
}

.serviceEditCont:hover  {
 background-color: nth($colors, 6);
 color: white;
}

.button {
  padding: 0.5rem 1rem;
  color: white;
  background: nth($colors, 1);
background: linear-gradient(304deg,  nth($colors, 1) 0%,  nth($colors, 6) 100%);
  font-family: nth($font-family, 4);
  border-radius: 5px;
  font-size: 0.8rem;

  max-width: 10rem;
  transition: 0.2s all ease-in-out;
  margin: 0.5rem auto;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.button:hover {
  color: nth($colors, 2);
  transform: translateY(1px);
}


.biggerButton {
  padding: 4rem 3rem;
  margin: 1rem;
  text-transform: uppercase;
  font-size: 1.5rem;

}

.biggerButton2 {
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  text-transform: uppercase;
  font-size: 1rem;

}

.absoluteTop {
  position: absolute;
  top: 2rem;
}

.newScheduleselectorCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
margin: 5rem auto;
}

.gap {
  margin: 0.5rem;

}



/* MEDIA QUERY */

@media only screen and (min-width: 601px) {

  .none-desktop {
      display: none !important;
  }
}

@media only screen and (min-width: 600px) and  (max-width: 1200px) {


}


@media only screen and (min-width: 100px) and  (max-width: 600px) {

  .none-mobile {
      display: none !important;
  }


  .serviceEditCont {
    padding: 1rem 2.2rem;
    height: 5rem;

  }

  
.schedulesCont {
  width: 90vw;

  
}

.wMobile {
  width: 10rem;
}
  

}




