@use 'admin/agenda';
@use 'admin/calendarPanel';
@use 'admin/dashboard';
@use 'admin/metrics';
@use 'admin/panel';
@use 'admin/roomsEditCont';
@use 'admin/items';

@use 'components/servicesCards';
@use 'components/book';
@use 'components/booking';
@use 'components/buttons';
@use 'components/inputs';
@use 'components/microsites';
@use 'components/progressBar';


@use 'base/size';
@use 'base/variables';

@use 'functions/mixins';
@use 'functions/list';
@use 'base/querys';





.displayNone {
    display: none;
}