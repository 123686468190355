@import '../functions/list';
.BookListCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0rem auto 0rem;
    padding: 0rem;
    transition: 0.5s ease-in-out all;
    background: none;
    border-radius: 10px;
}

.BookListCont2 {
    display: flex;
    flex-direction: column;
    width: 17rem;
    margin: 0rem auto 0rem;
    padding: 0rem;
    transition: 0.5s ease-in-out all;
    background: none;
    border-radius: 10px;
}

.BookListContNODATES {
    display: flex;
    flex-direction: column;
    width: 17rem;
    margin: 0rem auto 0rem;
    padding: 0rem;
    transition: 0.5s ease-in-out all;
    background-color: white;
    border-radius: 10px;

}

.christmas2 {
    position: absolute;
    top: -1rem;
    left: -.75rem;
    transform: rotate(-35deg);
}


.w500pxmax {
    width: 300px;
    flex-wrap: wrap;
}

.bggrey {
    background-color: rgb(245, 245, 245);
    margin: 1rem 0;
    padding: 0.5rem 2rem;
}

.monthText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 2rem;
    z-index: 100;
    font-weight: 400;
    margin: 0rem
}

.buttonred{
    padding: 0.25rem 0.5rem;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: rgb(203, 120, 120);    
    font-family: 'Barlow Condensed', sans-serif;
    border-radius: 5px;
    font-size: 0.8rem;
    width: fit-content;
    transition: 0.2s all ease-in-out;
    margin: 0.25rem auto;
    font-weight: 400;
    border: 1px solid red;
    cursor: pointer;
    animation: 1s enter;
  
  }


  .buttonred:hover {
    background-color: rgb(146, 54, 54);  

  }

.dayboxCont {
    height: 2rem;
    width: 2rem;
    margin: 0 auto;
    
}

.dayboxCont:hover {
    color: white;
    background-color: rgb(70, 70, 70);
}

.daybox {
    display: flex;
    flex-direction: row;
    font-family: 'Barlow Condensed', sans-serif;
    color: black;
    transition: 0.3s ease-in-out all;
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    line-height: 1.9rem;
    border-radius: 50%;
    margin: 0.2rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
}

.daybox:hover {
    color: white;
    background-color: nth($colors, 1);
}



.daybox:focus {
    color: white;
    background-color: nth($colors, 1);
}

.dayboxColor {
    color: white;
    background-color: nth($colors, 1);
    
}

.dayboxForbidden {

    display: flex;
    flex-direction: row;
    font-family: 'Barlow Condensed', sans-serif;
    transition: 0.3s ease-in-out all;
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    line-height: 1.9rem;
    border-radius: 50%;
    margin: 0.2rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    color:  nth($colors, 1);
    background-color: nth($colors, 3);
}

.dayboxForbidden:hover {
    background-color: nth($colors, 1)
}

.daysAvailmouseOver {
    position: absolute;
    left: 70%;
    top: 10rem;
    background-color: #2f4f4f;
    color: white;
    border-radius: 10px;
    padding: 1rem;
}





.availDot {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    margin-left: 0.2rem;
    color: rgb(0, 219, 66);
    position: relative;
    right: 0.6rem;
}

.red {
    color: rgb(223, 111, 111);
    text-decoration:line-through;
}

.pointbox {
    font-family: 'Barlow Condensed', sans-serif;
    color: #576F72;
    transition: 0.3s ease-in-out all;
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    margin: 0.2rem;
    text-align: center;
    cursor: pointer;
}

.fullavailable {
    color: white;
    background-color: rgb(106, 161, 114);
}

.agotado {
    color: white;
    background-color: nth($colors, 1);
}


.notfullavailable {
    color: white;
    background-color: nth($colors, 5);
}

.monthbox {
    font-family: 'Barlow Condensed', sans-serif;
    color: white;
    background-color: nth($colors, 1);
    border-radius: 5px;
    border-left: 3px solid nth($colors, 2);
    transition: 0.3s ease-in-out all;
    font-size: 0.9rem;
    width: 1.8rem;
    height: 2rem;
    line-height: 2rem;

    margin: 0.22rem;
    text-align: center;
    cursor: pointer;

}


.BookListMonth {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap:wrap;
    width: 100%;
    margin: 0rem;
}


.MonthButtonsCont {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    align-items: center;
}

.MonthButton {
    background: none;
    border: none;
    font-size: 1rem;
    margin: 0rem 2rem;
    font-weight: bold;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    transition: 0.3s ease-in-out all;
    cursor: pointer;
}

.MonthButton:hover {
    color: white;
    background-color: #576F72;
}

.selectionContCont {
    display: flex;
    flex-direction: row;
    margin: 1rem auto;
}

.selectionCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.selectionTextDate {
    font-size: 0.8rem;
    width: 3rem;
    height: 2rem;
    line-height: 2rem;
    margin: 0.2rem  0rem;
    color: white ;
    font-family: 'Barlow Condensed', sans-serif;
    background-color: #576F72;
    border-radius:0 5px 5px 0;
}



.selectionText {
    font-size: 0.8rem;
    height: 2rem;
    line-height: 2rem;
    padding: 0rem 0.5rem;
    margin: 0.2rem 0rem 0.2rem 0.2rem;
    border-radius: 5px 0 0 5px;
    color: white;
    font-family: 'Barlow Condensed', sans-serif;
    background-color: rgb(70, 70, 70);
}


.selectionDelete {
    font-weight: bolder;
    font-size: 0.8rem;
    height: 2rem;
    line-height: 2rem;
    padding: 0rem 0.5rem;
    margin: 0.2rem 0rem;
    color: white;
    background-color: rgb(211, 122, 122);
    border: none;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.selectionDelete:hover {
    background-color: rgb(206, 82, 82);
}

.checkcheck {
    font-family: 'Barlow Condensed', sans-serif;
    color: black;
    transition: 0.3s ease-in-out all;
    font-size: 1rem;
    width: 0.5rem;
    height: 0.5rem;
    line-height: 0.5rem;
    border-radius: 50%;
    margin: 0.1rem;
    text-align: center;
    cursor: pointer;
}

.yearAndMontCont {
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 20px;
    color: #2f4f4f;
}


.year {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    color: #576F72;
}



.loader {
 margin: 5rem 50%;
}

.displayNone {
    display: none;
}

.selectionGuestscont {
    margin: 2rem auto;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center ;
}

.textGuests {
    font-size: 1.2rem;
    height: 2rem;
    line-height: 2rem;
    padding: 0rem 0.5rem;
    margin: 0.2rem 0rem 0.2rem 0.2rem;
    border-radius: 5px 0 0 5px;
    color: #576F72;
    font-family: 'Barlow Condensed', sans-serif;
    background-color: #E4DCCF;
    
}

.selectionTextGuests {
    font-size: 1.2rem;    
    height: 2rem;
    line-height: 2rem;
    width: 3rem;
    margin: 0.2rem  0rem;
    color: white ;
    font-family: 'Barlow Condensed', sans-serif;
    background-color: #576F72;
}

.guestimg {
    width: 1.5rem;
    height: 1.5rem;
}

.buttonsGuestCont {
    display: flex;
    flex-direction: column;
    background-color: #7D9D9C; 
}

.buttonsGuest {
    background: none;
    border: none;
    height: 1rem;
    color: white;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.buttonsGuest:hover {
    background-color: #576F72;

}

/* BOOKBAR */

.bookbarcontOfCont {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.bookbarcont {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 3rem;
    border-radius: 0 0 15px 15px;
    margin: 0 auto;
    background-color: #576F72;
    color: white;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1.2rem;
    transition: 5s all ease-in-out;
    justify-content: center;
    position: relative;
    bottom: 0.2rem;
}

.bookbarDatecont {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0rem 1rem;
    height: 1rem;

}

.bookbarDetailCont {
    display: flex;
    flex-direction: row;
    margin: 1rem;
    align-items: center;
}

.bookbarimg {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.5rem;
    filter: invert(1);
}

.bookbarTittle {
    font-size: 2rem;
    font-weight: bolder;
    letter-spacing: 2px;
    font-family: 'Barlow Condensed', sans-serif;
    margin: 0.5rem 0rem;
    padding: 0rem 2rem;
    transition: all 0.5s ease-in-out;
    color: white;
   
}

.backtext {
    width: 100%;
    height: 1.4rem;
    margin: 0 auto;
    color: white;
    background: none;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 0.8rem;
    background-color: none;
    border: none;
    cursor: pointer;
    background-color: #7D9D9C;
    transition: 0.3s ease-in-out all;
}

.backtext:hover {
    background-color: #576F72;

}



/* SEARCH BUTTONS */
.searchRoomsButtonCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    background-color: #576F72;
    border-radius: 0px 0px 15px 15px;
}

.searchRoomsButton {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 0.2rem 0rem;
    color: white;
    border: none;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    background-color: #576F72;
    font-family: 'Barlow Condensed', sans-serif;

}

.searchRoomsButton:hover {
    color: #F0EBE3;
}

.searchRoomsButtonsDetailCont {
    display: flex;
    flex-direction: row;
    margin: 0rem 0.5rem;
    align-items: center;
}

.searchRoomsimg {
    height: 1rem;
    width: 1rem;
    filter: invert(1);
}

.bookImg {
    height: 1rem;
    width: 1rem;
    line-height: 1rem;
    aspect-ratio: 1/1;

}


.noRoomsAdv {
    width: 30%;
    font-size: 1.5rem;
    font-weight: bolder;
    letter-spacing: 2px;
    font-family: 'Barlow Condensed', sans-serif;
    margin: 3rem 0rem;
    padding: 0rem 2rem;
    transition: all 0.5s ease-in-out;
    color: #576F72;
}




/* MODAL */

.bookingsModal {
    display: flex;
    position: absolute;
    top: 0rem;
    font-family: 'Montserrat', sans-serif;
    width: 100vw;
    height: 100vh;
    background-color: rgb(145, 145, 145, 20%);
    backdrop-filter: blur(5px);
    justify-content: center;
    font-size: 1rem;
    padding-top: 3rem;
    z-index: 1000000 !important;

}

.closeModalWeek {
    height: fit-content;
    position: absolute;
    top: 0;
}

.pink {
    margin-left: 0.5rem;
    color:  nth($colors, 2);
}

.overflowYhidden {
    overflow-y: hidden;
}

.bookDataTop {
    background-color: nth($colors, 1);
}

.bookedModal {
    background-color: white;
    width: 40%;
    height: fit-content;
    margin-top: 5rem;
    border-radius: 5px;
    padding: 2rem;

}



.modalText{
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
width: 90%;
margin: 0.5rem auto;
align-items: center;
justify-content: center;
}

.modalMini {
    font-size: 0.8rem;
    margin: 0 0.5rem;
}


.highlight {
    color: white;
    line-height: 2rem;
    padding: 0.5rem;
    background-color: nth($colors, 1);
    border-radius:5px;
    margin-bottom: 1rem;
}


.modalImg {
    width: 1.5rem;
    height: 1.5rem;
}

.checkImg {
    animation: check infinite 2s ease-in-out;

}

.modalItemsImg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}


.reverse {
    transform: rotate(180deg);
}

.marginleft {
    margin-left: 0.25rem;
}

.contactButtonCont {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
bottom: 2rem;
right: 2rem;
    width: 4rem;
height:  4rem;
line-height: 4rem;
border-radius: 50%;
background-color: nth($colors, 1);
z-index: 200;
}

.contactButton {
width: 2.5rem;
height:  2.5rem;
filter: invert(1);
}

.dashboardCont {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: left;
}


.mapServicesCont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 400px;
    position: relative;
    border-radius: 10px;
    padding: 0;
    margin: 0 auto;
}

.servicesMapButton {
    width: 50%;
    height:  50%;
    gap: 0;
    margin: 0;
    padding: 0;
    border: none;
    opacity: 0%;
    background: none;
    cursor: pointer;
    transition: 0.5s all ease-in-out;
    border-radius: 10px;
    font-family: 'Barlow Condensed', sans-serif;
font-size: 1rem;
font-weight: 400;
}

.servicesMapButton:hover {
    opacity: 70%;
background-color: white;
color: nth($colors, 1);
font-family: 'Barlow Condensed', sans-serif;
font-size: 2rem;
font-weight: 400;

}

.serviceMapImg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    border-radius: 10px;

}

.buyOrder {
    background-color: nth($colors, 1);
    padding: 1rem 1rem;
    color: white;
    border-radius: 5px;
    font-family: nth($font-family, 4);
    font-size: 1.2rem;
    font-weight: bolder;
    margin: 1rem auto 0.5rem;
    text-align: center;
    transition: 0.5s all ease-in-out;
    cursor: url("https://img.icons8.com/small/16/f5f5f5/360-degrees.png"), auto;
    border: none;
    text-decoration: none;
    width: fit-content;

    transition: 0.5s all ease-in-out; 
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.checkoutTexts {
    width: fit-content;
    height: 1.5rem;
    line-height: 1.5rem;
    padding: 0.5rem 0.5rem;
    text-align: center;
    border: none;
    color: black;
    font-family: nth($font-family, 3);
    font-size: 1rem;
    transition: 0.3s all ease-in-out;
    opacity: 70%;
    margin: 1rem auto;
    width: 50%;
}

.paidImg {
    width: 40%;
    margin: 2rem;
}

.biggertext {
    font-size: larger;
}

.bankDataTexts {
    width: fit-content;
    padding: 0.2rem 0.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border: none;
    color: nth($colors, 1);
    font-family: nth($font-family, 3);
    font-size: 1rem;
    transition: 0.3s all ease-in-out;
    opacity: 70%;
    border-bottom: 3px nth($colors, 2) solid;
    border-radius: 2px;
    margin: 0;


}

.opacity50{
    opacity: .5;
}

.cancelation {
    margin: 0 auto;
    font-size: .65rem;
    color: rgb(255, 0, 0);
    background-color: rgb(255, 172, 172);
    // border: 1px solid red;
    padding: .25rem;
    width: fit-content;
    border-radius: 5px;
}
.notavailincart {
    opacity: .3;
    filter: grayscale(1);
    position: relative;
    cursor: not-allowed;
    font-size: .7rem;
    
}

.notavailincartad {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .25rem;
    position: absolute;
    box-sizing: border-box;
    top: -6px;
    right: 0px;
    background-color: rgb(71, 71, 71);
    color: white;
    font-family: "Rubik";
    padding: 1rem .45rem;
    border-radius: 0 10px 10px 0;
z-index: 1;
    font-size: .6rem;
    opacity: .5;
    cursor: not-allowed;
}


/* EFFECTS */

@keyframes check {
    0% {    transform: scale(1) rotate(0deg);
    }

    50% {    transform: scale(0.9) rotate(-20deg);
    }  

    100% {     transform: scale(1) rotate(0deg);
    }
}



///////// CART ////////////

.cart-cont {
    position: absolute;
    top: 0;
    right: 0;
}

/* MEDIA QUERY */

@media only screen and (min-width: 601px) {

    .none-desktop {
        display: none !important;
    }
 }

 @media only screen and (min-width: 600px) and  (max-width: 1200px) {


 }


 @media only screen and (min-width: 100px) and  (max-width: 600px) {

    .none-mobile {
        display: none !important;
    }


    .yearAndMontCont {
        background-color: white;
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;
        width: fit-content;
        margin: 0 auto;
        transition: 0.5s ease-in-out all;
    }

    .bookbarcont {
        flex-direction: column;
        width: 80%;
        height: fit-content;
        padding: 0.5rem 0rem;
        border-radius: 0 0 10px 10px;
        margin: 0 auto 1rem;
        font-size: 1.2rem;
        transition: 1s all ease-in-out;
    }

    .w500pxmax {
        max-width: 100%;
    }

    .bookbarDatecont {
        margin: 0.5rem 1rem;
        height: 1rem;
    }
    
   
    .bookbarimg {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0.5rem;
        filter: invert(1);
    }
    
    .bookbarTittle {
        font-size: 2rem;
        font-weight: bolder;
        letter-spacing: 2px;
        font-family: 'Barlow Condensed', sans-serif;
        margin: 0.5rem 0rem;
        padding: 0rem 2rem;
        transition: all 0.5s ease-in-out;
        color: #576F72;
    }
        
    .backtext {
        width: 80%;
        height: 1.7rem;
    }


    /* MODAL */
    
    .bookedModalcont {
        display: flex;
        position: absolute;
        top: 0rem;
        font-family: 'Montserrat', sans-serif;
        width: 100%;
        height: 150rem;
        background-color: rgb(145, 145, 145, 20%);
        backdrop-filter: blur(5px);
        justify-content: center;
        font-size: 1rem;
    }
    
.bookedModal {
    background-color: white;
    width: 80%;
    height: fit-content;
    margin-top: 5rem;
    border-radius: 5px;
    padding: 2rem;

}

}



/* EFFECTS */

@keyframes forbiddenDayEf {
    0% {    opacity: 0%;
    }

    30% {     opacity: 100%;;
    }   

    80% {     opacity: 100%;;
    } 

    100% {     opacity: 0%;;
    }
}
