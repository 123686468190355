@import '../functions/list';
.calendarCont{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

}

.calendarContWeekly{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    height: 85vh;
    overflow: auto;
 
}


.BookListContWeek {
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    margin: 0 auto;
    width: 100%;
}

.BookListContWeekMini {
    display: flex;
    flex-direction: row;
    scroll-behavior: smooth;
    margin: 0 auto;
    width: fit-content;
}

.yearAndMonthCont {
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    align-items: center;
    justify-content: center;
}

.dateSelectorCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 2rem;
}

.dateSelectorContMini {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 2rem;
}

.selector {
    background-color: nth($colors, 6);
    color: nth($colors, 2);;
    border-radius: 5px;
    margin: 0 1rem;
    border: none;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    padding: 0.25rem;
    width: 7rem;
}

.selector:focus{
    box-shadow: inset 0px -3px 0 0 nth($colors, 2);
}


.labelSelector {
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    color: black;
}



.thin {
    font-weight: 400;
    color: white;
}

.option {
    background-color: nth($colors, 1);

}




.year {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0;
}

.BookListWeek {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: fit-content;
    padding: 0rem;
    height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 0.5s all ease-in-out;
    border: 10px solid nth($colors, 3);
}


.BookListWeekMini {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: fit-content;
    padding: 0rem;
    height: fit-content;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 0.5s all ease-in-out;

}



.BookListWeekly {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: fit-content;
    padding: 0rem;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 0.5s all ease-in-out;
    border: 5px solid nth($colors, 3);
}




.orderCardCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 15rem;
    color: black;
    height: 2.75rem;
    padding: 0 0.5rem;
    cursor: pointer;

}

.weekorderCardCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 3rem;
    color: black;
    height: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;

}



.orderCardContStatus {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 2rem;
    height: 1.5rem;
    padding: 0.5rem;
  
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    background-color: nth($colors, 3 );
    color: white;
}

.scrollable-component {
    overflow: auto;
}

.greenStatus {
    color: white;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
    font-weight: bolder;
    background-color: nth($colors, 6 );
}


.redStatus {
    background-color: rgb(211, 122, 122);
    color: #b82b2b;
    cursor: pointer;
    font-weight: bolder;
    background-color: nth($colors, 6 );

}

.darkblue {
    background-color: nth($colors, 6);
}

.lightred {
    background-color: nth($colors, 7);
}

.pinkbg {
    background-color: nth($colors, 2);
}



.orderCardText {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    width: 100%;
    margin: 0;

}

.orderCardTextMini {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    width: 100%;
    margin: 0;

}


.weeklyOrderCardText {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.6rem;
    width: 100%;
    margin: 0;
}

.orderCardTextOnHover {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    margin: 0.1rem;
    background-color: nth($colors, 1);
    width: 10rem;
    color: white;
    height: fit-content;
    padding: 0.5rem;
    position: absolute;

}

.iconImg {
    width: 1.25rem;
    aspect-ratio: 1/1;
}

.rowOrderCardCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: whitesmoke;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    box-shadow: 0px 1px 0px nth($colors, 3) inset;
       
}

.relative {
    position: relative;
}



.h2blocksName {
    height: 5.5rem;
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}

.h3blocksName {
    height: 8.25rem;
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}

.h4blocksName {
    height: 11rem;
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}

.h5blocksName {
    height: 13.75rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}

.h6blocksName {
    height: 16.5rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}


.h7blocksName {
    height: 19.25rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}


.h8blocksName {
    height: 22rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}


.h9blocksName {
    height: 24.75rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}


.h10blocksName {
    height: 27.5rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}


.h11blocksName {
    height: 30.25rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}

.h12blocksName {
    height: 33rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}


.h13blocksName {
    height: 35.75rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}



.h14blocksName {
    height: 38.5rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}

.h15blocksName {
    height: 41.25rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}

.h16blocksName {
    height: 44rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}


.WEEKLYh2blocksName {
    height: 3rem;
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}

.WEEKLYh3blocksName {
    height: 4.5rem;
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}

.WEEKLYh4blocksName {
    height: 6rem;
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}

.WEEKLYh5blocksName {
    height: 7.5rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}

.WEEKLYh6blocksName {
    height: 9rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}


.WEEKLYh7blocksName {
    height: 10.5rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}


.WEEKLYh8blocksName {
    height: 12rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}


.WEEKLYh9blocksName {
    height: 13.5rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}


.WEEKLYh10blocksName {
    height: 15rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}


.WEEKLYh11blocksName {
    height: 16.5rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}

.WEEKLYh12blocksName {
    height: 18rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}


.WEEKLYh13blocksName {
    height: 19.5rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}



.WEEKLYh14blocksName {
    height: 21rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}

.WEEKLYh15blocksName {
    height: 22.5rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}

.WEEKLYh16blocksName {
    height: 24rem;   
    position: absolute;
    z-index: 100;
    left: 4rem;
    top: 0;
}




.ybg {
    color: black;
    box-shadow: inset 10px -2px 0 0 rgb(237, 169, 238);
}

.gbg {
    color: black;
    box-shadow: inset  10px -2px 0px 0 rgb(95, 175, 134);
   
}





.weekDayCont {
    min-width: 4rem;
    min-height: fit-content;
    color: white;
    margin: 0.2rem;
    border-radius: 5px;


}


.weekDayContSoldOut {
    min-width: 10rem;
    min-height: 25rem;
    margin: 0.5rem;
    border-radius: 5px;
    
}


.dayboxWeek {
    font-family: 'Barlow Condensed', sans-serif;
    color: black;
    transition: 0.3s ease-in-out all;
    font-size: 1.5rem;
    margin: 0;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;
    width: 100%;
    border-radius: 5px 5px 0 0;
}


.MonthButtonsCont {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
}

.MonthButton {
    background: none;
    border: none;
    font-size: 1rem;
    margin: 0rem 2rem;
    font-weight: bold;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    transition: 0.3s ease-in-out all;
    cursor: pointer;
}

.MonthButton:hover {
    color: white;
    background-color: darkslategrey;
}

.weekRoomCont {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    height: 3rem;
    width: 100%;
    color:  white;
    background-color: rgb(255, 255, 255, 20%);
    margin: 0.5rem;
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px;
    opacity: 80%;
    transition: 0.3s all ease-in-out;
    justify-content: center;
    font-size: 0.6rem;
    cursor: pointer;
 
}

.weekRoomCont:hover {
    opacity: 100%;
    transform: scale(1.02);
}

.firstColumn {
    color: rgb(0, 0, 0);

    background-color: rgb(255, 255, 255);
    border-radius: 0rem;
}



.soldout {
    color: rgb(255, 172, 172);
    padding: 2rem 0;
    font-size: 2.5rem;
    font-weight: bolder;
    background-color: rgb(161, 103, 103);
    border-radius: 5px;
    border-left: 4px solid rgb(146, 0, 0);
    border-right: 4px solid rgb(146, 0, 0);
    font-family: 'Barlow Condensed', sans-serif;

}

.whitetext {
    color: white;
}

/* FORWARD BUTTON */

.forwardButton {
    position: absolute;
    top: 45%;
    right: 1rem;
    font-size: 3rem;
    background: none;
    border: none;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.forwardButton:hover {
    color: rgb(92, 112, 92); 
}

.backwardsButton {
    position: absolute;
    top: 35%;
    right: 1.2rem;
    font-size: 3rem;
    background: none;
    border: none;
    cursor: pointer;
    transition: 0.3s all ease-in-out;

}

.backwardsButton:hover {
    color: rgb(170, 79, 79);
}


.arrivesTrue {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgb(92, 112, 92);
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
}

.arrivesFalse {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgb(170, 79, 79);
    font-family: 'Barlow Condensed', sans-serif;
}

.arrivesImg {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.5rem;
}

.bookingsModal {
    position: absolute;
    top: 0rem;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
        backdrop-filter: blur(5px);
    background-color: rgb(145, 145, 145, 20%);

}


.dashboardBookOrderWeek {
    color: black;
    display: flex;
    flex-direction: column;
    width: 30%;
    height: fit-content;
    padding: 1rem;
    margin: 1rem auto;
    align-items: center;
    transition: 0.3s all ease-in-out;
    border-radius: 10px;
    background: #ffffff;


}



.closeModalWeek {

    font-family: 'Montserrat', sans-serif;
    color: rgb(170, 79, 79);
    border: none;
    background-color: rgb(223, 153, 153);
    border-bottom: 4px solid rgb(170, 79, 79);
    padding: 0.2rem 4rem;
    font-size: 1rem;
    font-weight: bolder;
    border-radius: 0 0 5px 5px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.closeModalWeek:hover {
    padding: 0.4rem 4rem;
}

.guestArriving {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    height: fit-content;
    padding: 0.5rem 0;
    width: 95%;
    font-size: 0.5rem;
    margin: 0.5rem;
    color:  white;
    background-color: rgba(80, 67, 197);
    border-left:  4px solid rgb(10, 1, 92);
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px;
    opacity: 80%;
    transition: 0.3s all ease-in-out;
    justify-content: center;
    cursor: pointer;

}

.guestArriving:hover {
    opacity: 100%;
    transform: scale(1.02);
}


.departures {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    height: fit-content;
    padding: 0.5rem 0;
    width: 95%;
    font-size: 0.5rem;
    margin: 0.5rem;
    color:  white;
    background-color: rgb(67, 197, 143);
    border-left: 4px solid rgb(1, 92, 72);
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px;
    opacity: 80%;
    transition: 0.3s all ease-in-out;
    justify-content: center;
    cursor: pointer;
}

.departures:hover {
    opacity: 100%;
    transform: scale(1.02);
}

.displayNone {
    display: none;
}

.weekcalendarloader {
    margin: 20rem auto;
}


/*  MICRO CALENDAR */


.microCalendarListCont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 30rem;
    padding: 1rem;
    justify-content: left;
    overflow-y: hidden;
    overflow-x: hidden;
    transition: 0.5s all ease-in-out;
    margin: 2rem auto;
    background-color: whitesmoke;
    border-radius: 10px;
}

.microCalendarDayCont {

    width: 4.5rem;
    min-height: 1.5rem;
    color: black;
    margin: 0;
}

.MicroCalendarRoomCont {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.7rem;
    font-weight: 100;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    height: fit-content;
    padding: 0.2rem 0.2rem;
    width: 4rem;
    color:  white;
    background-color: rgb(255, 255, 255, 20%);
    opacity: 80%;
    transition: 0.3s all ease-in-out;
    justify-content: center;

}


.MicroCalendardayboxWeek {
    font-family: 'Barlow Condensed', sans-serif;
    color: rgb(87, 87, 87);
    transition: 0.3s ease-in-out all;
    margin: 0;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;
    width: 100%;
    border-radius: 5px 5px 0 0;

}


.viewDetailButton {
    background: none;
    border: none;
    font-size: 0.8rem;
    background-color: whitesmoke;
    cursor: pointer;
    padding: 0.2rem;
    font-family: 'Barlow Condensed', sans-serif;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;
}

.viewDetailButton:hover {
    background-color: #576F72;
    color: white;
}

.fadein {
    transition: 0.3s all ease-in-out;
    opacity: 100%;
}

.fadeout {
    transition: 0.3s all ease-in-out;
    opacity: 0%;
}

.avail {
    color: #576F72;
    background-color: #b5d3d2;

}

.notavail {
    color: rgb(170, 79, 79);
    background-color: rgb(223, 153, 153);
}

.partial {
    color: rgb(156, 118, 12);
    border-left: 4px solid rgb(156, 118, 12);
}

.completeMonthCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dayEditorCont {
    position: absolute;
    top: 0rem;
left: 0rem;
    backdrop-filter: blur(5px);
    background-color: rgb(145, 145, 145, 20%);

    color: black;

    width: 100%;
    height: 100%;
    z-index: 400;
 

}

.dayEditorBox {

    top: 2rem;
    background-color:  whitesmoke;
    border-radius: 5px;
    padding: 2rem;
    border-left: 4px solid black;
    width: 10rem;
    margin: 2rem auto;
}

.dayEditorDate {

    top: 0rem;
    left: 1rem;
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    opacity: 50%;
      border: none;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    z-index: 1000;
}

.button2 {
    padding: 0.25rem 0.5rem;
    color: nth($colors, 1);
    background: nth($colors, 5);
  background: linear-gradient(304deg,  nth($colors, 5) 0%,  nth($colors, 2) 100%);
    font-family: nth($font-family, 4);
    border-radius: 5px;
    font-size: 0.7rem;
    width: fit-content;
    transition: 0.2s all ease-in-out;
    margin: 0.5rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    animation: 1s enter;
    z-index: 100;
  }

  .button2Desbloquear {
    padding: 0.25rem 0.5rem;
    color: nth($colors, 1);
    background: nth($colors, 5);
  background: linear-gradient(304deg,  nth($colors, 5) 0%,  nth($colors, 2) 100%);
    font-family: nth($font-family, 4);
    border-radius: 5px;
    font-size: 0.7rem;
    width: fit-content;
    transition: 0.2s all ease-in-out;
    margin: 0.5rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    animation: 1s enter;

  }

  
.button2FitCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.5rem;
    color: nth($colors, 1);

//     background: nth($colors, 5);
//   background: linear-gradient(304deg,  nth($colors, 5) 0%,  nth($colors, 2) 100%);
    font-family: nth($font-family, 4);
    border-radius: 5px;
    font-size: 0.6rem;
    height: 1.5rem;
    transition: 0.2s all ease-in-out;
    margin: 0.5rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    animation: 1s enter;
  }

  
  .buttonbyn2 {
    padding: 0.5rem;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  background: linear-gradient(304deg,  rgb(246, 246, 246) 0%,  rgb(255, 255, 255) 100%);
    font-family: nth($font-family, 4);
    border-radius: 5px;
    font-size: 0.8rem;
    width: fit-content;
    transition: 0.2s all ease-in-out;
    margin: 0.25rem;
    font-weight: 400;
    border: 1px solid rgb(213, 213, 213);
    cursor: pointer;

  }


  .buttonbyn {
    padding: 0.25rem 0.5rem;
    color: black;

  background: linear-gradient(304deg,  rgb(246, 246, 246) 0%,  rgb(255, 255, 255) 100%);
    font-family: nth($font-family, 4);
    border-radius: 5px;
    font-size: 0.7rem;
    width: fit-content;
    transition: 0.2s all ease-in-out;
    margin: 0.25rem;
    font-weight: 400;
    border: 1px solid rgb(213, 213, 213);
    cursor: pointer;

  }

  .greengradient {
      display: flex;
      flex-direction: column;
      align-items:center;
      justify-content: center;
      gap: 0.2rem;
      padding: 0.2rem 1rem;
    color: white;
    font-weight: bold;
    background: linear-gradient(304deg,  rgb(103, 181, 115) 0%,  rgb(67, 222, 123) 100%);
    border: rgb(0, 255, 85);
    z-index: 2000;
    opacity: 50%;
    transition: .5s all ease-in-out;
    cursor: pointer;
    position: absolute;
    top: 1rem;

    max-width: 50vw;
  }

  .beat {
    animation: opacitybeat 2s infinite;
  }

  .solid {
    opacity: 100%;
  }
  .greengradient:hover {
    opacity: 100%;
  }
  .arrownnew {
    margin: 0;
    font-size: 1.5rem;
    transform: rotate(90deg);
  }

  @keyframes opacitybeat {
    0% {     opacity: 100%}
    50% {      opacity: 40%} 
    100% {     opacity: 100%} 
}



  @keyframes arrowmovement {
    0% {     opacity: 100%}
    50% {      opacity: 40%} 
    100% {     opacity: 100%} 
}


  .marginzero {
    margin: 0;
  }
  .redgradient {
    
    color: white;
    font-weight: bold;
    background: linear-gradient(304deg,  rgb(236, 166, 166) 0%,  rgb(254, 143, 143) 100%);
    border: red;
  }

  .bluegradient {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 100;
    border: none;
    padding: .5rem;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    background: none;
    color: black;
    border-radius: 10px;
    filter: grayscale(0.5);
    background: nth($colors, 2);
    background: linear-gradient(304deg, nth($colors, 5) 0%, nth($colors, 2) 100%);
    cursor: pointer;    
    transition: 0.2s all ease-in-out;
    margin: 1rem auto;
    width: fit-content;
  }

  .bluegradient:hover {
    filter: grayscale(0);

  }

  
  .margin2 {
    margin: 0 .2rem;
    font-style: oblique;
  }
  

  .button2:hover {
    color: white;
    transform: translateY(1px);
  }

  .button2Desbloquear:hover {
    color: white;
    transform: translateY(1px);
  }
  
  .button3 {
    padding: 0.25rem 0.5rem;
    color: white;
    background: nth($colors, 6);
    font-family: nth($font-family, 4);
    border-radius: 5px;
    font-size: 0.7rem;
    width: fit-content;
    transition: 0.2s all ease-in-out;
    margin: 0.5rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    animation: 1s enter;
  }

  .button3:hover {
    background: nth($colors, 1);
  }

  .button2minitext {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;
    padding: 0.15rem 0.25rem;
  }

  .cancelButton44 {
    padding: 0.25rem 0.5rem;
    color: white;
    background: red;
  background: linear-gradient(304deg, red 0%,  rgb(198, 98, 98) 100%);
    font-family: nth($font-family, 4);
    border-radius: 5px;
    font-size: 0.7rem;
    width: fit-content;
    transition: 0.2s all ease-in-out;
    margin: 0.5rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    animation: 1s enter;

  }
  
  .cancelButton44:hover {
    color: nth($colors, 2);
    transform: translateY(1px);
  }
  
  .showAddressCont {
    position: relative;

  }

  .showAddressText {
    position: absolute;
    padding: 0.5rem;
    background-color: nth($colors, 1);
    color: white;
    font-family: nth($font-family, 4);
    border-radius: 5px;
    right: 0rem;
    bottom: 0rem;
    width: 5rem;
    z-index: 100;
  }

  .w3rem {
    width: 3rem;

}

.w5rem {
    width: 5rem;

}

.w5rem2 {
    width: 5rem;

}


.w10rem {
    width: 5rem;
}

.w2rem {
    width: 2rem;

}

.thinLine {
    height: 1.5rem;
}


/* MEDIA QUERY */

@media only screen and (min-width: 601px) {

    .none-desktop {
        display: none !important;
    }
 }

 @media only screen and (min-width: 600px) and  (max-width: 1200px) {


 }


 @media only screen and (min-width: 100px) and  (max-width: 600px) {

    .dashboardBookOrderWeek {
        width: 90%;
    }

    .BookListContWeek {
        margin: 0;
    }

    .weekRoomCont {
        width: 4rem;
        height: 5rem;
        font-size: 0.8rem;
    }
    
    .weekDayCont {
        min-width: 4rem;
        min-height: fit-content;
        color: white;
        margin: 0.35rem;
        border-radius: 5px;
    
    }

    .orderCardCont {
        width: 3rem;
    }

    .w5rem2 {
        width: 3rem;
    }
    .w2rem {
        width: 1rem;
    
    }

    .orderCardText {
        font-family: 'Montserrat', sans-serif;
        font-size: 0.5rem;
        word-wrap: break-word;
        width: 100%;

        text-overflow: ellipsis; 
    }

    .weeklyOrderCardText {
        font-family: 'Montserrat', sans-serif;
        font-size: 0.5rem;
        word-wrap: break-word;
        width: 100%;

        text-overflow: ellipsis; 
    }
    
    
.weekorderCardCont {
    width: 3rem;
}




    .button2FitCont {
        font-size: 0.5rem;
    }
    
    .weekDayContSoldOut {
        min-width: 5rem;
        min-height: 50rem;
        margin: 0.35rem;
        border-radius: 5px;
        
    }


    .iconImg {
        width: 1rem;
        height: 1rem;
        aspect-ratio: 1/1;
    }

    .BookListWeek {
        justify-content: left;
        overflow-x: auto;
    }
    
    .firstColumn {
        color: rgb(0, 0, 0);
        background-color: rgb(255, 255, 255);
        border-radius: 0rem;
        width: 5rem;
        font-size: 0.5rem;
    }

    .backwardsButton {
        position: absolute;
        top: 60%;
        right: 38%;
        font-size: 2rem;

    
    }
    
    .forwardButton {
        position: absolute;
        top: 60%;
        right: 30%;
        font-size: 2rem;
    }

    .weekcalendarloader {
        margin: 15rem auto
    }


    .completeMonthCont {
        flex-direction: column;

    }

    .dateSelectorCont {
   
        flex-direction: column;

    }

    .thin {
        font-size: 0.6rem;
    }

    .orderCardTextMini {
        font-family: 'Montserrat', sans-serif;
        font-size: 0.8rem;
        width: 100%;
        margin: 0;
    
    }
    
    .bluegradient2 {
        flex-direction: column;
    }

    .greengradient {
        top: unset;
      bottom: 7rem;
  
      max-width: 80vw;
    }
  
}