@import './scss/functions/list';

.App {
  text-align: center;
  background-color: #ffffff;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;

}


::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-thumb {
  background: nth($colors, 3);
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: nth($colors, 3) 0px 0px 3px inset;
  border-radius: 10px;
}



@keyframes enter {
  0% {
    opacity: 0;
    filter: brightness(2);
  }
}