/* LISTAS */
.App {
  text-align: center;
  background-color: #ffffff;
  -webkit-tap-highlight-color: transparent;
    overflow-x: hidden;

}

::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-thumb {
  background: #c3c5f8;
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: #0010d5 0px 0px 5px inset;
          box-shadow: #0010d5 0px 0px 5px inset;
  border-radius: 10px;
}


@font-face {
  font-family: "Fontspring-DEMO-bogue-regular";
  src: url("../fonts/Bogue-Serif-Font-Family/Fontspring-DEMO-bogue-regular.otf");
}

@font-face {
  font-family: "Fontspring-DEMO-bogue-bold";
  src: url("../fonts/Bogue-Serif-Font-Family/Fontspring-DEMO-bogue-bold.otf");
}

.micrositeTittle {
  font-family: "Fontspring-DEMO-bogue-bold";
  font-size: 3.5rem;

  z-index: 100;
  font-weight: 400;
  margin: 0rem 0rem 2rem;
}

.subtittleBogue {
  font-size: 3.5rem;
}


.minitextBogue {
  font-size: 2rem;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;

}

.superminitextBogue {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.blue {
  color: #0010d5;
}


@media only screen and (min-width: 100px) and  (max-width: 600px) {


  .micrositeTittle {
    font-size: 2rem;

}
.minitextBogue {
  font-size: 1.4rem;
}

.superminitextBogue {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}
}
/*# sourceMappingURL=App.css.map */